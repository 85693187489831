import { useState, useEffect } from 'react';

function parseJwt(token: string) {
    // Code from https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

const useCheckConnection = (timeout: number) => {
    const [isExpired, setIsExpired] = useState(false);
    const key = `token`;
    useEffect(() => {
        const checkExpiration = () => {
            const item = localStorage.getItem(key);
            if (item) {
                try {
                    const decodedToken = parseJwt(item);
                    const currentTime = Date.now() / 1000; // Get current time in seconds

                    if (decodedToken.exp < currentTime) {
                        localStorage.clear();
                        setIsExpired(true);
                    } else {
                        setIsExpired(false);
                    }
                } catch (error) {
                    console.error('Error decoding JWT:', error);
                    setIsExpired(true);
                }
            } else {
                localStorage.clear();
                setIsExpired(true);
            }
        };

        checkExpiration();
        

        const interval = setInterval(checkExpiration, timeout);

        return () => clearInterval(interval);
    }, [key, timeout]);

    return isExpired;
};

export default useCheckConnection;
