import { TabMenuItem } from './TabMenuItem';

class ModuleDetailHandlerCreate {
    public handleTabMenu(data: any) {
        const tabMenus = data.tabMenuItems;
        return tabMenus.map((menu: any) => {
            return (
                <TabMenuItem
                    key={menu.id}
                    title={menu.title}
                    selected={menu.selected}
                    icon={menu.icon}
                    onClick={() => {
                        if (data.module?.id) {
                            data.setSelectedPanel(menu.icon);
                        }
                    }}
                />
            );
        });
    }
}

export default ModuleDetailHandlerCreate;
