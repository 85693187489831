import { Box, Typography } from '@mui/material';

import Modal from '@mui/material/Modal';
import { ChangeEvent, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import { ADD_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { VideoUploader } from '../../EducativeBilan/TabPanel/VideoPanel/VideoUploader';
import { TOOLBOX_VIDEO_CATEGORY } from 'src/utils/constants/constants';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '70vh',
    minHeight: 335,
    borderRadius: 2,
    bgcolor: 'background.paper',
    p: 4,
};

export const AddVideo = (props: any) => {
    const { setReloadVideo } = props;

    const [open, setOpen] = useState(false);
    const [fileName, setFileName] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>('');
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);

    const handleClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const [addVideo, { data, loading, error }] = useMutation(ADD_TOOL_BOX_MUTATION, {
        onCompleted: (data) => {
            setReloadVideo((prev: boolean) => !prev);
            setOpen(false);
        },
        onError: (error) => {
            alert('add video error');
        },
    });

    const handleSubmit = () => {
        addVideo({
            variables: {
                title: title,
                description: description,
                fileContent: fileName,
                fileName,
                category: TOOLBOX_VIDEO_CATEGORY
            },
            onCompleted: (data: any) => {},
        });
        reInitialize();
    };

    const reInitialize = () => {
        setFileName('');
        setTitle('');
        setDescription('');
        setIsUploaded(false);
    };

    const getFile = (data: any) => {
        // console.log(data);
        if (data.file) {
            setFileName(data.file.uniqueIdentifier + '.' + data.file.name.split('.').pop());
        }
    };

    const getUploadState = (data: any) => {
        setIsUploaded(data);
    };

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    return (
        <Box>
            <CustomButton label={' Ajouter une vidéo '} width={160} lower={true} fontSize={12} onClick={handleOpen}></CustomButton>
            <Modal  
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        fontFamily={baseFontFamily.BreeSerif}
                        fontSize={16}
                        color={baseColors.black}
                        textAlign={'center'}
                        marginBottom={5}
                    >
                        Nouvelle vidéo
                    </Typography>
                    <div style={{ width: '100%', height: '85%' }}>
                        <div style={{ float: 'left', width: '20%', height: '90%' }}>
                            <div style={{ width: '100%', height: '65px' }}>
                                <label>
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansRegular}
                                        fontSize={12}
                                        color={baseColors.black}
                                    >
                                        Titre (<b style={{color: 'red'}}>*</b>) :
                                    </Typography>
                                </label>
                            </div>
                            <div style={{ width: '100%', height: '125px' }}>
                                <label>
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansRegular}
                                        fontSize={12}
                                        color={baseColors.black}
                                    >
                                        Description (<b style={{color: 'red'}}>*</b>) :
                                    </Typography>
                                </label>
                            </div>
                            <div style={{ width: '100%', height: '65px' }}>
                                <label>
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansRegular}
                                        fontSize={12}
                                        color={baseColors.black}
                                    >
                                        Type :
                                    </Typography>
                                </label>
                            </div>
                            <div style={{ width: '100%', height: '75px' }}>
                                <label>
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansRegular}
                                        fontSize={12}
                                        color={baseColors.black}
                                    >
                                        Importer le fichier :
                                    </Typography>
                                </label>
                            </div>
                        </div>
                        <div style={{ float: 'left', width: '80%', height: '90%' }}>
                            <div style={{ width: '100%', height: '65px' }}>
                                <TextField multiline name="title" value={title} onChange={onTitleChange} fullWidth />
                            </div>
                            <div style={{ width: '100%', height: '125px' }}>
                                <TextareaAutosize
                                    style={{ width: '99.5%', height: '90%' }}
                                    value={description}
                                    onChange={onDescriptionChange}
                                />
                            </div>
                            <div style={{ width: '100%', height: '75px' }}>
                                <div style={{ width: '155px' }}>
                                    <VideoUploader onChange={getFile} onUploaded={getUploadState}></VideoUploader>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '75px' }}>
                            <Box style={{ textAlign: 'center' }}>
                                <CustomButton
                                    label={' Annuler '}
                                    width={100}
                                    boxShadow={'none'}
                                    background={baseColors.green.cancel}
                                    color={baseColors.white}
                                    onClick={handleClose}
                                    marginRight={'8px'}
                                ></CustomButton>
                                {loading === true ? (
                                    <CustomCircleLoader width={30} />
                                ) : (
                                    <CustomButton
                                        label={' Ajouter '}
                                        width={100}
                                        disabled={
                                            !title ||
                                            !description ||
                                            !fileName ||
                                            !isUploaded
                                        }
                                        marginRight={'8px'}
                                        onClick={handleSubmit}
                                    ></CustomButton>
                                )}
                            </Box>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Box>
    );
};
