import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { SelectPromotion } from 'src/components/shared/SelectPromotion/SelectPromotion';
import { SelectPromotionEvent } from 'src/components/shared/SelectPromotion/types/types';
import { StudentList } from 'src/components/shared/StudentList/StudentList';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { baseColors } from 'src/utils/constants/baseStyles';
import { AuthorizationCode } from 'src/utils/constants/constants';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { GlobalAvancement } from './AvancementGlobale/GlobalAvancement';
import { IndividualAvancement } from './AvancementIndividuel/IndividualAvancement';
import { SummaryActions } from './SummaryAction/SummaryActions';

export const DashboardPanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    const [promotionId, setPromotionId] = useState<string>('');
    const [needPrerequisite, setNeedPrerequisite] = useState<boolean | null | undefined>(false);
    const { codes } = useAuthorizationContext();
    const isAuthorizedToSeeIndividualAvancement = codes?.includes(
        AuthorizationCode.FRONT_DISPLAY_STUDENT_GLOBAL_PROGRESS,
    );
    const isAuthorizedToSeeGlobalAvancement = codes?.includes(
        AuthorizationCode.FRONT_DISPLAY_PROMOTION_GLOBAL_PROGRESS,
    );

    const handleChange = (e: SelectPromotionEvent) => {
        if (e.selectedPromotionId) {
            setPromotionId(e.selectedPromotionId);
            setNeedPrerequisite(e?.needPrerequisite);
        }
    };

    return (
        <Box sx={{ ...styles.rootPanel }}>
            <Paper sx={{ ...styles.paperPanel }}>
                {isAuthorizedToSeeIndividualAvancement && (
                    <>
                        <Box
                            style={{
                                backgroundColor: baseColors.white,
                                borderRadius: 6,
                                width: '100%',
                                height: '100%',
                                // overflow: 'auto',
                            }}
                        >
                            <IndividualAvancement></IndividualAvancement>
                        </Box>
                        <Box
                            style={{
                                backgroundColor: baseColors.white,
                                width: 0,
                                height: 0,
                                marginLeft: 0,
                                marginRight: 0,
                            }}
                        ></Box>
                    </>
                )}
                {isAuthorizedToSeeGlobalAvancement && (
                    <>
                        <Box sx={{ height: '100%', width: '25%', minWidth: '200px' }}>
                            <StudentList promotionId={promotionId} />
                        </Box>
                        <Box
                            style={{
                                width: '100%',
                                height: '100%',
                                marginLeft: 8,
                                marginRight: 8,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: baseColors.white,
                                    padding: '25px',
                                    borderRadius: 6,
                                    width: '100%', // Set width to 100%
                                    boxSizing: 'border-box', // Ensure padding is included in the width calculation
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <SelectPromotion handleChange={handleChange} />
                            </Box>
                            <Box
                                style={{
                                    backgroundColor: baseColors.white,
                                    borderRadius: 6,
                                    width: '100%',
                                    flexGrow: 1,
                                    marginTop: 8,
                                    overflow: 'auto',
                                }}
                            >
                                <GlobalAvancement promotionId={promotionId} needPrerequisite={needPrerequisite} />
                            </Box>
                        </Box>
                    </>
                )}
            </Paper>
        </Box>
    );
};
