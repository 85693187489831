import { Box, Paper, Typography } from '@mui/material';
import ContentPanelViewModel from 'src/pages/Home/Content/ContentPanelViewModel';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

export const ModuleNonValide = () => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();

    return (
        <Paper sx={styles.paperPanel}>
            <Box sx={styles.rootPanel} style={{ padding: 20, backgroundColor: 'white' }}>
                <Typography fontSize={10.66} fontFamily={baseFontFamily.OpenSansSemiBold} color={baseColors.black}>
                Ce module n'est accessible que si vous avez envoyé un fichier PDF dans le menu <b>Prérequis</b>.
                </Typography>
            </Box>
        </Paper>
    );
};
