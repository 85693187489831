import React, { FC, useContext, useEffect, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import useStyles from '../Login/styles';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomRadioGroup } from 'src/components/RadioGroup/CustomRadioGroup';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_USER_CONSENT_MUTATION } from 'src/graphql/mutations/UserConsentMutation';
import { CustomCheckbox } from 'src/components/Checkbox/CustomCheckbox';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { UserConsentHeader } from './UserConsentHeader';
import { UserConsentBody } from './UserConsentBody';
import { GET_USER_CONSENT } from 'src/graphql/queries/queries';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { margin } from '@mui/system';
import { Box } from '@mui/material';
import { useAuthContext } from 'src/providers/AuthProvider';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/routes/routes';
import useCheckConnection from 'src/hooks/useCheckConnection';
interface IProps {
    setModal?: Function;
    width?: string;
    userConsentUpdated?: any;
}

const UserConsent: FC<IProps> = (props) => {
    const { setModal, width, userConsentUpdated } = props;
    const { loading: loadingData, data: userConsentData, error: errorData } = useQuery(GET_USER_CONSENT);
    const [prevData, setPrevData] = useState({ cgu: 0, major: 0, services: 0, statistics: 0, communication: 0 });
    const classes = useStyles();
    const [cgu, setCgu] = useState(false);
    const [major, setMajor] = useState(false);
    const [services, setServices] = useState(0);
    const [statistics, setStatistics] = useState(0);
    const [communication, setCommunication] = useState(0);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const navigate = useNavigate();
    const { isLoggedIn, setIsLoggedIn, setIsLoadingLogout, isLoadingLogout } = useAuthContext();
    const isExpired = useCheckConnection(10000);
    useEffect(() => {
        if (isExpired) {
            setIsLoggedIn && setIsLoggedIn(false);
            setIsLoadingLogout(false);
            navigate(ROUTES.login);
        }
    }, [isExpired]);
    useEffect(() => {
        if (userConsentData?.getUserConsentCurrentUser) {
            setPrevData(userConsentData.getUserConsentCurrentUser);
        }
    }, [userConsentData]);
    useEffect(() => {
        setCommunication(prevData.communication);
        setServices(prevData.services);
        setStatistics(prevData.statistics);
        prevData.cgu === 0 ? setCgu(false) : setCgu(true);
        prevData.major === 0 ? setMajor(false) : setMajor(true);
    }, [prevData]);


    const Handler: any = {
        setCgu: setCgu,
        setMajor: setMajor,
        setServices: setServices,
        setStatistics: setStatistics,
        setCommunication: setCommunication,
    };

    const UserConsentData: any = {
        cgu,
        major,
        services,
        statistics,
        communication,
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, param: String) => {
        const funcId = `set${param}`;
        Handler[funcId](parseInt((event.target as HTMLInputElement).value));
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, param: String) => {
        const funcId = `set${param}`;
        Handler[funcId](event.target.checked);
    };

    const [saveUserConsent, { loading, error, data }] = useMutation(ADD_USER_CONSENT_MUTATION, {
        variables: {
            cgu: UserConsentData.cgu === true ? 1 : 0,
            major: UserConsentData.major === true ? 1 : 0,
            services: UserConsentData.services,
            statistics: UserConsentData.statistics,
            communication: UserConsentData.communication,
        },
        onCompleted: () => {
            showSuccessSnackBar('Votre consentement est ajouté avec success!');
            userConsentUpdated?.();
            setModal?.(false);
        },
        onError: () => {
            showErrorSnackBar();
        },
    });

    return (
        <Box style={{ padding: '0 60px', height: '100%', overflowY: 'auto', textAlign: 'justify' }}>
            <div>
                <UserConsentHeader></UserConsentHeader>
                <CustomCheckbox
                    value={cgu}
                    label="J’accepte les présentes conditions générales d’utilisation et créé un compte personnel."
                    handleChange={(event: any) => handleCheckboxChange(event, 'Cgu')}
                    color={baseColors.orange.primary}
                    labelColor={baseColors.green.primary}
                ></CustomCheckbox>
                <CustomCheckbox
                    value={major}
                    label="Je certifie être majeur, ne bénéficiant pas d’une mesure de tutelle"
                    handleChange={(event: any) => handleCheckboxChange(event, 'Major')}
                    color={baseColors.green.primary}
                    labelColor={baseColors.green.primary}
                ></CustomCheckbox>

                <UserConsentBody></UserConsentBody>

                <CustomRadioGroup
                    label={
                        ' Pour la gestion des services fournis par IVEHTE et notamment pour la gestion de l’accès et du fonctionnement du site IVEHTE et de l’application IVEHTE.'
                    }
                    value={services}
                    handleChange={(event: any) => handleChange(event, 'Services')}
                    color={baseColors.green.primary}
                    // labelStyle={{ width: '60%' }}
                ></CustomRadioGroup>
                <Spacer height={20}></Spacer>
                <CustomRadioGroup
                    label={
                        ' Pour la réalisation de statistiques concernant l’activité du site IVEHTE et de l’application IVEHTE ainsi que l’utilisation des services fournis par IVEHTE. A cet effet, mes données peuvent être anonymisées.'
                    }
                    value={statistics}
                    handleChange={(event: any) => handleChange(event, 'Statistics')}
                    color={baseColors.orange.primary}
                    // labelStyle={{ width: '60%' }}
                ></CustomRadioGroup>
                <Spacer height={20}></Spacer>
                <CustomRadioGroup
                    label={' Pour la communication d’offres commerciales de la part d’IVEHTE ou de ses partenaires'}
                    value={communication}
                    handleChange={(event: any) => handleChange(event, 'Communication')}
                    color={baseColors.orange.primary}
                ></CustomRadioGroup>
                <Spacer height={20}></Spacer>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <CustomButton
                            label={'Valider'}
                            disabled={cgu === false ? true : false}
                            width={150}
                            onClick={saveUserConsent}
                        ></CustomButton>
                    </div>
                </div>
            </div>
        </Box>
    );
};
export default UserConsent;
