import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { LIST_ALL_PROMOTIONS } from 'src/graphql/queries/queries';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { SelectPromotionEvent, SelectPromotionProps } from './types/types';
import { green } from '@mui/material/colors';

const SelectPromotion: FC<SelectPromotionProps> = (props: SelectPromotionProps) => {
    const { handleChange } = props;

    const [promotionList, setPromotionList] = useState<any[]>([]);
    const [promotionId, setPromotionId] = useState<string>('');
    const selectStyle = {
        color: baseColors.green.primary,
        outline: 'none',
        borderRadius: '7px',
        margin: '0 10px',
        ':checked ': {
            color: 'red',
        },
    };

    const { loading, data: dataPromotionListQuery, error } = useQuery(LIST_ALL_PROMOTIONS);

    const dataPromotionList: any[] = [];

    const handleChangePromotion = (id: string) => {
        handleChange({
            selectedPromotionId: id,
            needPrerequisite: promotionList.find((promotion) => promotion.id === id)?.needPrerequisite,
        } as SelectPromotionEvent);
    }

    useEffect(() => {
        if (dataPromotionListQuery) {
            let lastPromotionId = dataPromotionListQuery.getAllPromotion.slice(-1)[0]?.id;
            setPromotionList((oldstate: any[]) => {
                return dataPromotionListQuery.getAllPromotion.map((promotion: any) => {
                    return {
                        id: promotion.id,
                        name: promotion.name,
                        needPrerequisite: promotion.needPrerequisite,
                    };
                });
            });
            setPromotionId(lastPromotionId);
        }
    }, [dataPromotionListQuery]);

    useEffect(() => {
        if(promotionId){
            handleChangePromotion(promotionId);
        }
    },[promotionId])
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <label htmlFor="promotion" style={{ paddingRight: '25px', whiteSpace: 'nowrap' }}>
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        fontWeight={600}
                        color={baseColors.green.cancel}
                        fontSize={14}
                    >
                        Promotion :
                    </Typography>
                </label>
                <select
                    value={promotionId}
                    disabled={loading ? true : false}
                    id="promotion"
                    onChange={(e) => {
                        setPromotionId(e.target.value);
                        handleChangePromotion(e.target.value);
                    }}
                    style={{
                        color: baseColors.black,
                        outline: 'none',
                        borderRadius: '10px',
                        padding: '10px 20px 10px 20px',
                        border: '2px solid',
                        width: '100%',
                        height: '40px',
                        borderColor : baseColors.grey,
                        backgroundColor : baseColors.green.light
                    }}
                >
                    <option value="none" defaultValue={0} hidden>
                        Sélectionnez une promotion
                    </option>
                    {promotionList.map((promotion) => {
                        return (
                            <option key={promotion.id} value={promotion.id} style={{ color: baseColors.green.cancel }}>
                                {promotion.name}
                            </option>
                        );
                    })}
                </select>
            </Box>
        </>
    );
};
export { SelectPromotion };
