import moment from 'moment';

export const dbDateFormat = 'YYYY-MM-DD';
export const dbDateTimeFormat = 'YYYY-MM-DD HH:mm';
export const localDateFormat = 'DD/MM/YYYY';

const addZeroBeforeNumber = (value: number) => (value < 10 ? `0${value}` : value);

const checkIncludedValue = (start: number, end: number, value: number) => Boolean(value >= start && value <= end);

const checkIfEmpty = (itemCount: number) => itemCount === 0;

const isEven = (n: number) => n % 2 === 0;

const isOdd = (n: number) => Math.abs(n % 2) === 1;

const formatDateFormat = (date: Date) => {
    const covertedDate = new Date(date);
    if (covertedDate) {
        return `${covertedDate.getFullYear()}-${addZeroBeforeNumber(covertedDate.getMonth() + 1)}-${addZeroBeforeNumber(
            covertedDate.getDate(),
        )}`;
    }
    return '';
};

const timeWithoutTimeZone = (date: string | number, withSeparator?: boolean) => {
    if (withSeparator) {
        return moment(date).utcOffset(date).format('HH:mm');
    }
    return moment(date).utcOffset(date).format('HHmm');
};

const formatToDate = (date: string | number) => {
    if (!date) {
        return null;
    }
    
    return moment(date).utcOffset(date).format('DD/MM/YYYY');
};

const dateWithoutTimeZone = (date: string | number) => moment(date).utcOffset(date).format('YYYY-MM-DD HH:mm:ss');

const getCurrentDateTime = (): string => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const convertToCustomFormat = (isoDate: string): string => {
    const normalizedDate = isoDate.replace(/([+-]\d{2}:\d{2})|Z$/, "");
    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, "0"); // Day of the month (2 digits)
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (2 digits, 0-based index)
    const year = date.getFullYear(); // Year

    const hours = String(date.getHours()).padStart(2, "0"); // Hours (2 digits)
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Seconds (2 digits)

    return `${day}/${month}/${year} à ${hours}:${seconds}`;
};

const fullName = (firstName: string | null, lastName: string | null) => {
    if (!firstName || !lastName) {
        return '';
    }
    if (firstName && !lastName) {
        return firstName;
    }
    if (!firstName && lastName) {
        return lastName;
    }
    if (firstName && lastName) {
        return `${lastName} ${firstName}`;
    }

    return '';
};

const frenchDateFormat = (date: Date) => {
    let newDate = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
    });
    newDate = newDate.charAt(0).toUpperCase() + newDate.slice(1);

    return newDate;
};

const currentUrlOrigin = window.location.origin.toString();

const transformDayNameToFrench = (dayName: string, date: string) => {
    const getDay = date && date.split('-')[2];

    switch (dayName) {
        case 'Monday':
            return `Lundi ${getDay}`;

        case 'Tuesday':
            return `Mardi ${getDay}`;

        case 'Wednesday':
            return `Mercredi ${getDay}`;

        case 'Thursday':
            return `Jeudi ${getDay}`;

        case 'Friday':
            return `Vendredi ${getDay}`;

        case 'Saturday':
            return `Samedi ${getDay}`;

        default:
            return `Dimanche ${getDay}`;
    }
};

const differenceBetweenTwoDates = (d1: Date, d2: Date) => {
    const start = moment(d1);
    const end = moment(d2);
    const result = end.diff(start, 'days');

    return result + 1;
};

function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const isNull = (value: any) => value === null;

const transformTimeToLocaleTimeString = (date: Date) => date.toLocaleTimeString('fr-FR');

const transformDateToLocaleDate = (date: string) => {
    const dateAsArray = date.split('-').map((d) => d.trim());
    return dateAsArray.reverse().join('/').trim();
};

const specialCharToUnderscore = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '_');

const getMonthAndYear = (date: string) => moment(date).utcOffset(date).format('DD-MM');

const monthAndYear = (month: number, year: number) => {
    switch (month) {
        case 2:
            return `Fevrier ${year}`;

        case 3:
            return `Mars ${year}`;

        case 4:
            return `Avril ${year}`;

        case 5:
            return `Mai ${year}`;

        case 6:
            return `Juin ${year}`;

        case 7:
            return `Juillet ${year}`;

        case 8:
            return `Août ${year}`;

        case 9:
            return `Septembre ${year}`;

        case 10:
            return `Octobre ${year}`;

        case 11:
            return `Novembre ${year}`;

        case 12:
            return `Décembre ${year}`;

        default:
            return `Janvier ${year}`;
    }
};

const getDayAndMonth = (date: string) => moment(new Date(date)).format('Do MMM');

const displaySex = (sex: String) => {
    switch (sex) {
        case 'MALE':
            return 'Masculin';
        case 'FEMALE':
            return 'Féminin'
        default:
            return null;
    }
};

export {
    currentUrlOrigin,
    isNull,
    checkIncludedValue,
    checkIfEmpty,
    isEven,
    formatDateFormat,
    isOdd,
    timeWithoutTimeZone,
    dateWithoutTimeZone,
    fullName,
    frenchDateFormat,
    transformDayNameToFrench,
    transformTimeToLocaleTimeString,
    randomIntFromInterval,
    differenceBetweenTwoDates,
    transformDateToLocaleDate,
    specialCharToUnderscore,
    getMonthAndYear,
    addZeroBeforeNumber,
    monthAndYear,
    getDayAndMonth,
    formatToDate,
    displaySex,
    getCurrentDateTime,
    convertToCustomFormat
};
