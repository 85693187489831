import { Box } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { selectCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
// import { selectCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';
import { educativeBilanTabMenus } from 'src/utils/constants/constants';
import ModuleDetailHandler from './ModuleDetailHandler';
import ModuleDetailViewModel from './ModuleDetailViewModel';

const ModuleDetailTab = (props: any = {}) => {
    const module = useSelector(selectCurrentModule);
    const vm = new ModuleDetailViewModel();
    const handler = new ModuleDetailHandler();
    const styles = vm.getStyles();
    const { selectedPanel, setSelectedPanel } = useModuleDetailContext();
    let tabMenuItems = educativeBilanTabMenus;

    if (!module?.moduleIsValidated) {
        tabMenuItems = educativeBilanTabMenus.filter((menu: any) => {
            return menu.showInCreateModule;
        });
    }

    return (
        <Box sx={styles.detailTab}>
            <Box sx={styles.tabMenuContainer}>
                {handler.handleTabMenu({ tabMenuItems, selectedPanel, setSelectedPanel })}
            </Box>
            <Box sx={styles.separator}></Box>
        </Box>
    );
};

export default ModuleDetailTab;
