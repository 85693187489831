import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { CustomConfirm } from "src/components/CustomConfirm/CustomConfirm";
import CustomCircleLoader from "src/components/Loader/CustomCircleLoader";
import { VALIDATE_MODULE, VALIDATE_MODULE_EDUCATIVE_WORKSHOP } from "src/graphql/mutations/module.mutations";
import { GET_ALL_ACTIONS_IN_EDUCATIVE_BILAN, GET_ALL_ACTIONS_IN_EDUCATIVE_WORKSHOP } from "src/graphql/queries/summary.queries";
import { ModuleType } from "src/utils/constants/constants";
import { SummaryActionsTable, SummaryActionsType } from "./SummaryActionsTable";


interface IProps {
    moduleType: ModuleType,
    promotionId?: string,
    moduleId?: string
};

const SummaryActions: FC<IProps> = ({ moduleType, moduleId, promotionId }) => {
    moduleId = moduleId ?? "defaultModuleId";
    const [summaryActions, setSummaryActions] = useState<SummaryActionsType[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);

    const onCompletedMutation = (data: any) => {
        setShowModal(false);
        updateSummaryAction();
    }
    const onErrorMutation = (error: any) => {
        console.error(error);
        alert("there is error in console");
    }
    const [validateModuleMutation, mutationData] = useMutation(VALIDATE_MODULE, {
        onCompleted: onCompletedMutation,
        onError: onErrorMutation
    });

    const [validateModuleEducativeWorkshopMutation, mutationValidateModuleEducativeWorkshopData] = useMutation(VALIDATE_MODULE_EDUCATIVE_WORKSHOP, {
        onCompleted: onCompletedMutation,
        onError: onErrorMutation
    });

    let gql = GET_ALL_ACTIONS_IN_EDUCATIVE_BILAN, gqlKey = "";
    if (moduleType == ModuleType.EDUCATIVE_BILAN) {
        gql = GET_ALL_ACTIONS_IN_EDUCATIVE_BILAN;
        gqlKey = "getAllActionsInEducativeBilan";
    }
    else if (moduleType == ModuleType.EDUCATIVE_WORKSHOP) {
        gql = GET_ALL_ACTIONS_IN_EDUCATIVE_WORKSHOP;
        gqlKey = "getAllActionsInEducativeWorkshop";
    }
    const { loading, error, data, refetch } = useQuery(gql, {
        variables: {
            promotionId: promotionId,
            moduleId: moduleId
        }
    });

    const showModalConfirm = (userId: any) => {
        setSelectedUserId(userId);
        setShowModal(true);
    }

    const updateSummaryAction = () => {
        setSummaryActions((oldSummaryActions: any[]) => {
            let newSummaryActions = [];
            for (let i = 0; i < oldSummaryActions.length; i++) {
                newSummaryActions.push(oldSummaryActions[i]);
                if (oldSummaryActions[i].user.id != selectedUserId) {
                    continue;
                };
                newSummaryActions[i].isValidated = true;
            }
            return newSummaryActions;
        });
    }

    const validateModule = () => {
        if (moduleType == ModuleType.EDUCATIVE_BILAN) {
            validateModuleMutation({
                variables: {
                    moduleId: moduleId,
                    userId: selectedUserId
                }
            });
        } else if (moduleType == ModuleType.EDUCATIVE_WORKSHOP) {
            validateModuleEducativeWorkshopMutation({
                variables: {
                    moduleId: moduleId,
                    userId: selectedUserId
                }
            });
        }
        refetch();
    }

    useEffect(() => {
        if (data) {
            setSummaryActions(() => {
                return data[gqlKey].map((summary: any) => {
                    return {
                        user: summary.student,
                        seenVideos: summary.moduleSummary.seenVideos,
                        totalVideos: summary.moduleSummary.totalVideos,
                        readDocuments: summary.moduleSummary.readDocuments,
                        totalDocuments: summary.moduleSummary.totalDocuments,
                        filledForm: summary.moduleSummary.filledForm,
                        totalForm: summary.moduleSummary.totalForm,
                        filledFields: summary.moduleSummary.filledFields,
                        totalFields: summary.moduleSummary.totalFields,
                        isValidated: summary.moduleSummary.isValidated,
                        isFinalized: summary.moduleSummary.isFinalized
                    } as SummaryActionsType;
                });
            });
        }
    }, [data]);

    if (error) console.error(error);

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            {loading ?
                <CustomCircleLoader width={60} /> :
                error ?
                    <div>Oups, something isn't working</div> :
                    <>
                        <SummaryActionsTable onClickValidate={showModalConfirm} data={summaryActions} />
                        <CustomConfirm
                            message="Voulez-vous vraiment valider?"
                            callBackconfirm={validateModule}
                            callbackCancel={() => (setShowModal(false))}
                            modalVisible={showModal}
                            disableBackdropClick={true}></CustomConfirm>
                    </>
            }
        </Box>
    )

}

export { SummaryActions };