import { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Avatar, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomButton } from 'src/components/Button/CustomButton';
import { InputField } from 'src/components/InputField/InputField';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { useMutation } from '@apollo/client';
import { SEND_PDF_TO_PROFESSOR } from 'src/graphql/mutations/profile.mutation';
import { SnackBarContext } from 'src/providers/SnackBarProvider';

interface ContactUsProps {
    fileId: string;
    open: boolean;
    setOpen: Function;
}

interface SendDocumentForm {
    email: string;
    object: string;
    message: string;
    fileId: string;
}

export const ContactUs: FC<ContactUsProps> = (props) => {
    const { open, setOpen, fileId } = props;
    const [sendingMail, setSendingMail] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isOtherEmail, setIsOtherEmail] = useState(false);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [sendPdfToProfessor, mutationData] = useMutation(SEND_PDF_TO_PROFESSOR, {
        onCompleted: (data, clientOptions?) => {
            showSuccessSnackBar('Votre fichier a été envoyé avec succès');
            setOpen(false);
        },
        onError: (data, clientOptions?) => {
            showErrorSnackBar("Désolé, votre fichier n'a pas pu être envoyé");
        },
    });
    const defaultValueSendDocument: SendDocumentForm = {
        email: 'placeholderValue',
        object: '',
        message: '',
        fileId: fileId,
    };

    const [sendDocument, setSendDocument] = useState<SendDocumentForm>(defaultValueSendDocument);
    const updateSendDocument = (key: string, value: string) => {
        setSendDocument({
            ...sendDocument,
            fileId: fileId,
            [key]: value,
        });
    };

    useEffect(() => {
        if (sendDocument.email != '' && sendDocument.object != '' && sendDocument.message != '') {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
        if (sendDocument.email != '' && sendDocument.email == 'otherEmail') {
            setIsOtherEmail(true);
        }
    }, [sendDocument]);

    useEffect(() => {
        if (!open) {
            setSendDocument({
                email: 'placeholderValue',
                object: '',
                message: '',
                fileId: '',
            });
            setIsOtherEmail(false);
        }
    }, [open]);

    return (
        <div>
            <CustomModal width={500} open={open} toggleModal={setOpen}>
                <div>
                    <CustomText
                        style={{ textAlign: 'center' }}
                        fontFamily={baseFontFamily.BreeSerif}
                        fontSize={18}
                        marginBottom={5}
                        color={baseColors.black}
                    >
                        {`Envoie Fichier`}
                    </CustomText>
                </div>
                <Grid style={{ marginTop: 20, marginBottom: 10 }} container direction="row" alignItems={'center'}>
                    <Grid container direction="row" style={{ marginTop: 20, marginBottom: 10 }} alignItems={'center'}>
                        <Grid md={3} item>
                            <CustomText>Adresse e-mail :</CustomText>
                        </Grid>
                        <Grid md={9} item>
                            <FormControl sx={{ width: '95%' }}>
                                {!isOtherEmail && (
                                    <Select
                                        sx={{ width: '95%' }}
                                        name="email"
                                        onChange={(e) => {
                                            updateSendDocument('email', e.target.value);
                                        }}
                                        value={sendDocument.email}
                                    >
                                        <MenuItem disabled value="placeholderValue">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.black}
                                                fontSize="12.33px"
                                            >
                                                Email du professeur
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="professor@yopmail.com">professor@yopmail.com</MenuItem>
                                        <MenuItem value="professor2@yopmail.com">professor2@yopmail.com</MenuItem>
                                        <MenuItem value="professor3@yopmail.com">professor3@yopmail.com</MenuItem>
                                        <MenuItem value="otherEmail">autre email</MenuItem>
                                    </Select>
                                )}
                                {isOtherEmail && (
                                    <InputField
                                        single={true}
                                        label={''}
                                        onChange={(value: any) => {
                                            updateSendDocument('email', value);
                                        }}
                                        errorMessage="Adresse email obligatoire"
                                        placeholder={'Entrer adresse email'}
                                        width={'95%'}
                                        idSuffix="email_professionel"
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginTop: 20, marginBottom: 10 }} alignItems={'center'}>
                        <Grid md={3} item>
                            <CustomText>Objet :</CustomText>
                        </Grid>
                        <Grid md={9} item>
                            <FormControl sx={{ width: '95%' }}>
                                <InputField
                                    single={true}
                                    disabled={sendingMail}
                                    label={''}
                                    onChange={(value: any) => {
                                        updateSendDocument('object', value);
                                    }}
                                    placeholder={"Objet de l'email"}
                                    width={'95%'}
                                    idSuffix="objet_send_file"
                                    value={sendDocument.object}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: 10, marginTop: 20 }} alignItems={'center'}>
                        <Grid md={3} item>
                            <CustomText> Message :</CustomText>
                        </Grid>
                        <Grid md={9} item flexGrow={1} style={{ marginBottom: 10 }}>
                            <FormControl sx={{ width: '95%' }}>
                                <InputField
                                    single={true}
                                    height={200}
                                    label=""
                                    disabled={sendingMail}
                                    multiline
                                    placeholder={'Taper votre texte'}
                                    onChange={(value: any) => {
                                        updateSendDocument('message', value);
                                    }}
                                    width={'95%'}
                                    value={sendDocument.message}
                                    idSuffix="message_send_file"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <CustomButton
                            width={'50%'}
                            label="Envoyer"
                            loading={sendingMail}
                            disabled={
                                !isFormValid ||
                                sendDocument.email === 'otherEmail' ||
                                sendDocument.email === 'placeholderValue'
                            }
                            onClick={() => {
                                sendPdfToProfessor({
                                    variables: {
                                        input: {
                                            recipientMail: sendDocument.email,
                                            fileId: sendDocument.fileId,
                                            message: sendDocument.message,
                                            subject: sendDocument.object,
                                        },
                                    },
                                });
                            }}
                        ></CustomButton>
                    </Grid>
                </Grid>
            </CustomModal>
        </div>
    );
};
