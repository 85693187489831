import { Box, List, Paper, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { ErrorText } from 'src/components/Error/ErrorText';
import { LIST_MODULES, LIST_MODULES_BY_TYPE_WITH_VIDEO } from 'src/graphql/queries/module.queries';
// import { LIST_MODULES_BY_TYPE } from 'src/graphql/queries/module.queries';
import { Module } from 'src/interfaces/interfaces';
import { ModuleCreationButtonPanel } from './ModuleCreationButtonPanel';
import { MediaType, ModuleItem } from './ModuleItem';
import ModuleListViewModel from './ModuleListViewModel';
import { useQuery } from '@apollo/client';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { AuthorizationCode, BilanTabMenuIconName } from 'src/utils/constants/constants';
import { ModuleDetailContext, useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { useDispatch } from 'react-redux';
import { setCurrentModuleId, setFirstModule, setListModules } from 'src/redux/features/listModules/listModulesSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
    createNewModule,
    selectCurrentModule,
    setCurrentModule,
} from 'src/redux/features/listModules/listModulesSlice';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const ModuleListPanel = (props: any = {}) => {
    const dispatch = useDispatch();
    const currentModule = useSelector(selectCurrentModule);
    const allModulesInStore = useSelector((state: RootState) => state.listModulesReducer.modules);
    const { setSelectedPanel } = useModuleDetailContext();
    const { setIsCreatingNewModule } = useContext(ModuleDetailContext);
    const { codes } = useAuthorizationContext();

    const vm = new ModuleListViewModel();
    const styles = vm.getStyles();
    let arrayModulesValidated: any = [];

    const isAuthorizedToSeeFirstVideo = codes?.includes(AuthorizationCode.FRONT_DISPLAY_EDUCATIVE_BILAN_FIRST_VIDEO);
    const isAuthorizedToPLayVideo = codes?.includes(AuthorizationCode.FRONT_PLAY_EXPLICATIVE_VIDEO);

    const { loading, error } = useQuery(LIST_MODULES_BY_TYPE_WITH_VIDEO, {
        variables: {
            type: props.moduleType,
            limit: 5,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            let allModules = data.listModules;

            // On filtre les modules si c'est un etudiant
            if (isAuthorizedToSeeFirstVideo) {
                allModules.forEach((m: any) => {
                    if (m.moduleIsValidated) {
                        arrayModulesValidated.push(m);
                    }
                });
            } else {
                arrayModulesValidated = allModules;
            }
            // setListModules in the store
            dispatch(setListModules(arrayModulesValidated));
            dispatch(setFirstModule());
        },
    });

    if (error) {
        console.error(error);
    }

    const renderModuleItem = (module: any, index: number) => {
        if (isAuthorizedToPLayVideo) {
            return (
                <ModuleItem
                    key={index}
                    module={module}
                    selected={module.id === currentModule.id}
                    onClick={() => {
                        // dispatch(setCurrentModule(module));
                        dispatch(setCurrentModuleId(module.id));
                        setIsCreatingNewModule(false);
                        setSelectedPanel(BilanTabMenuIconName.INFORMATION);
                    }}
                    mediaType={MediaType.VIDEO}
                />
            );
        } else {
            return (
                <ModuleItem
                    key={index}
                    module={module}
                    selected={module.id === currentModule.id}
                    onClick={() => {
                        // dispatch(setCurrentModule(module));
                        dispatch(setCurrentModuleId(module.id));
                        setSelectedPanel(BilanTabMenuIconName.INFORMATION);
                        setIsCreatingNewModule(false);
                    }}
                    mediaType={MediaType.THUMBNAIL}
                />
            );
        }
    };
    return (
        <Box
            sx={{
                ...styles.listContainer,
                '@media screen and (max-width: 750px)': {
                    height: '100vh',
                },
            }}
        >
            <Paper sx={styles.listContent}>
                <Box
                    style={{
                        padding: '25px',
                        width: '100%',
                        height: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box sx={styles.listTitle}>
                        <Typography
                            fontFamily={baseFontFamily.BreeSerif}
                            fontWeight={400}
                            color={baseColors.green.cancel}
                            fontSize={18}
                        >
                            Liste des modules
                        </Typography>
                    </Box>

                    <Box>
                        {error ? <ErrorText>Une erreur est survenue lors du chargement des modules</ErrorText> : ''}
                    </Box>

                    {loading ? (
                        <CustomCircleLoader width={30} />
                    ) : allModulesInStore.length !== 0 ? (
                        <List sx={styles.scrollableListContainer}>
                            {allModulesInStore.map((module: any, index: number) => {
                                return renderModuleItem(module, index);
                            })}
                        </List>
                    ) : (
                        isAuthorizedToSeeFirstVideo && <>Aucun module soumis par le professeur</>
                    )}
                    {!isAuthorizedToSeeFirstVideo && (
                        <ModuleCreationButtonPanel
                            handleClick={() => {
                                setIsCreatingNewModule(true);
                                dispatch(createNewModule());
                                setSelectedPanel(BilanTabMenuIconName.INFORMATION);
                            }}
                        />
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default ModuleListPanel;
