import { TabMenuItem } from "./TabMenuItem";

class ModuleDetailHandler {
    public handleTabMenu(data: any) {
        const { selectedPanel, setSelectedPanel } =  data
        const tabMenus = data.tabMenuItems;
        return tabMenus.map((menu: any) => {
            return (
                <TabMenuItem
                    key={menu.id}
                    title={menu.title}
                    selected={selectedPanel === menu.icon}
                    icon={menu.icon}
                    onClick={() => {
                        setSelectedPanel(menu.icon);
                    }}
                />
            );
        });
    }
}

export default ModuleDetailHandler;
