import { Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { Promotion } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useEffect, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import { ReloadingContext } from 'src/providers/ReloadingProvider';
import { UPDATE_PROMOTION, DELETE_PROMOTION } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';

type PromotionListTabProps = {
    promotions: Promotion[];
    loading: boolean;
};

const PromotionListTab = (props: PromotionListTabProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const { setIsReload } = useContext(ReloadingContext);
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [promotions, setPromotions] = useState(props.promotions);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const clearInput = () => {
        setName('');
        setId('');
    };

    useEffect(() => {
        setPromotions(props.promotions);
    }, [props.promotions]);

    const [updatePromotion, { data: dataPromotion, loading: loadingCreatePromotion, error: errorCreatingPromotion }] =
        useMutation(UPDATE_PROMOTION, {
            variables: {
                id: id,
                name: name,
            },
            onCompleted: (output) => {
                setIsEditing(false);
                showSuccessSnackBar('Promotion modifiée avec succès');
                setIsReload(true);
                setPromotions((prevPromotions) =>
                    prevPromotions.map((promotion) =>
                        promotion.id === id ? { ...promotion, isEditable: false } : promotion,
                    ),
                );
                clearInput();
            },
            onError: (erreur) => {
                console.error(erreur);
                showErrorSnackBar('Une erreur est survenue lors de la modification de la promotion');
            },
        });

    const [deletePromotion, { data: dataDelete, loading: loadingDelete, error: errorDelete }] = useMutation(
        DELETE_PROMOTION,
        {
            variables: {
                id: id,
            },
            onCompleted: (output) => {
                clearInput();
                showSuccessSnackBar('Promotion modifiée avec succès');
                setIsReload(true);
                setOpen(false);
            },
            onError: (erreur) => {
                console.error(erreur);
                showErrorSnackBar('Une erreur est survenue lors de la modification de la promotion');
            },
        },
    );

    const renderRow = (row: Promotion) => {
        return (
            <tr className="tableRow">
                <td className="tableCell">
                    {row.isEditable ? (
                        <>
                            <Box>
                                <TextField
                                    onChange={handleChange} // Use handleChange directly
                                    value={name}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        backgroundColor: baseColors.white,
                                        '& .Mui-focused': {
                                            border: '1px solid',
                                            borderColor: baseColors.green.primary,
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        width: '80%',
                                    }}
                                />
                            </Box>
                        </>
                    ) : (
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.green.cancel}
                            fontSize={13}
                        >
                            {row.name}
                        </Typography>
                    )}
                </td>
                <td className="tableCell">
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                        {row.isEditable ? (
                            <>
                                <CustomButton
                                    width="auto"
                                    height="35px"
                                    label="Enregistrer"
                                    onClick={updatePromotion}
                                    fontSize={14}
                                    lower={true}
                                    loading={loadingCreatePromotion}
                                />
                            </>
                        ) : (
                            <>
                                {!isEditing && (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                setName(row.name);
                                                setId(row.id);
                                                setIsEditing(true);
                                                setPromotions((prevPromotions) =>
                                                    prevPromotions.map((promotion) =>
                                                        promotion.id === row.id
                                                            ? { ...promotion, isEditable: true }
                                                            : promotion,
                                                    ),
                                                );
                                            }}
                                        >
                                            <EditIcon sx={{ color: baseColors.green.primary }} />
                                        </IconButton>
                                        {row.isDeletable && (
                                            <IconButton
                                                onClick={() => {
                                                    setId(row.id);
                                                    setOpen(true);
                                                }}
                                            >
                                                <DeleteIcon sx={{ color: baseColors.orange.primary }} />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </td>
            </tr>
        );
    };

    return (
        <Paper>
            <table className="table">
                <tr className="tableHeader">
                    {/* <th className="tableCell tableCellHeader" style={{ color: baseColors.green.primary }}>
                        Id
                    </th> */}
                    <th className="tableCell tableCellHeader">
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Nom
                        </Typography>
                    </th>
                    <th className="tableCell tableCellHeader" style={{ color: baseColors.green.primary }}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.primary}
                                fontSize={13}
                            >
                                Actions
                            </Typography>
                        </Box>
                    </th>
                </tr>
                {promotions.map((promotion) => renderRow(promotion))}
            </table>

            <CustomConfirm
                modalVisible={open}
                updateModalVisible={setOpen}
                yesLabel={'OUI'}
                noLabel={'ANNULER'}
                callBackconfirm={deletePromotion}
                disableBackdropClick={false}
                message={'Voulez-vous vraiment supprimer cette promotion ?'}
            />
        </Paper>
    );
};

export default PromotionListTab;
