import CustomLinearProgress from 'src/components/Progress/CustomLinearProgress';
import summaryActionsTableStyle from './styles/SummaryActionsTableStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomButton } from 'src/components/Button/CustomButton';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { useState } from 'react';
import { Typography } from '@mui/material';

type SummaryActionsType = {
    user: any;
    seenVideos: number;
    totalVideos: number;
    filledForm: number;
    totalForm: number;
    readDocuments: number;
    totalDocuments: number;
    filledFields: number;
    totalFields: number;
    isFinalized: boolean;
    isValidated: boolean;
};

type SummaryActionsTableProps = {
    onClickValidate: Function;
    data: SummaryActionsType[];
};

const SummaryActionsTable = ({ data, onClickValidate }: SummaryActionsTableProps) => {
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const classes = summaryActionsTableStyle();

    const handleValidateRow = (userId: string) => {
        setSelectedRow(userId);
        // show modal
        setShowModal(true);
    };
    const renderLinearProgress = (value: number, max: number) => {
        const label = `${value} / ${max}`;
        return (
            <>
                {value === 0 ? (
                    <CustomLinearProgress
                        value={value}
                        max={max}
                        min={0}
                        status="notStarted"
                        withLabel={true}
                        label={label}
                    />
                ) : value === max ? (
                    <CustomLinearProgress
                        value={value}
                        max={max}
                        min={0}
                        status="done"
                        withLabel={true}
                        label={label}
                    />
                ) : (
                    <CustomLinearProgress
                        value={value}
                        max={max}
                        min={0}
                        status="inProgress"
                        withLabel={true}
                        label={label}
                    />
                )}
            </>
        );
    };

    const renderRow = (rowData: SummaryActionsType, index: number) => (
        <tr key={index} className={classes.rowTable}>
            <td className={classes.cellTable}>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontWeight={baseFontWeight.medium}
                    color={baseColors.green.cancel}
                    fontSize={11}
                >
                    {rowData.user.lastName.toUpperCase()}
                </Typography>
            </td>
            <td className={classes.cellTable}>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontWeight={baseFontWeight.medium}
                    color={baseColors.green.cancel}
                    fontSize={11}
                >
                    {rowData.user.firstName}
                </Typography>
            </td>
            <td className={classes.cellTableValue}>{renderLinearProgress(rowData.seenVideos, rowData.totalVideos)}</td>
            <td className={classes.cellTableValue}>
                {renderLinearProgress(rowData.readDocuments, rowData.totalDocuments)}
            </td>
            <td className={classes.cellTableValue}>
                {renderLinearProgress(rowData.filledFields, rowData.totalFields)}
            </td>
            <td className={classes.cellTableValue}>{renderLinearProgress(rowData.filledForm, rowData.totalForm)}</td>

            <td className={classes.cellTableValue}>
                {rowData.isFinalized && rowData.isValidated ? ( // TODO, test if module is validated
                    <CheckCircleIcon color="success"></CheckCircleIcon>
                ) : rowData.isFinalized ? (
                    <CustomButton
                        label={'Valider'}
                        fontSize={8}
                        width={100}
                        onClick={() => onClickValidate(rowData.user.id)}
                    ></CustomButton>
                ) : (
                    <></>
                )}
            </td>
        </tr>
    );
    return (
        <>
            <table className={classes.baseTable}>
                <thead>
                    <tr className={classes.rowTable}>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Nom
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Prénom
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Vidéos visionnées
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                 fontSize={11}
                            >
                                {' '}
                                Documents ouverts
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Champs remplis
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Formulaires remplis
                            </Typography>{' '}
                        </th>
                        <th className={classes.cellTableHeader}>
                            {' '}
                            <Typography
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                color={baseColors.green.cancel}
                                fontSize={11}
                            >
                                {' '}
                                Actions
                            </Typography>{' '}
                        </th>
                    </tr>
                </thead>
                <tbody>{data.map(renderRow)}</tbody>
            </table>
            <CustomConfirm
                message="Voulez-vous vraiment valider?"
                callBackconfirm={() => {
                    onClickValidate(selectedRow);
                }}
                callbackCancel={() => {
                    setShowModal(false);
                }}
                modalVisible={showModal}
                disableBackdropClick={true}
            ></CustomConfirm>
        </>
    );
};

export { SummaryActionsTable };
export type { SummaryActionsType };
