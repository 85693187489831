import { Box } from '@mui/material';
import { VideoContent } from 'src/pages/Home/Content/Module/VideoContent';
import useStyles from 'src/components/Cards/styles/videoCardListStyles';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { VideoCardItem } from './VideoCardItem';
import { Dispatch, SetStateAction } from 'react';

type VideoCardListProps = { 
    dataRows: any[], 
    loading: false, 
    readOnly: false, 
    setReloadVideo: Dispatch<SetStateAction<boolean>>,
}

export const VideoCardList = (props: VideoCardListProps) => {
    const dataRows = props.dataRows;
    const loading = props.loading;
    const readOnly = props.readOnly;
    const setReloadVideo = props.setReloadVideo;
    const classes = useStyles();

    return (
        <Box className={classes.videoContainer}>
            <div className={classes.patientListContainer}>
                <>
                    {readOnly ? (
                        <Box style={{
                            width : '100%',
                            height: '100%',
                            display : 'flex',
                            justifyContent : 'space-around'
                        }}>
                            {
                                dataRows.map((video: any) => (<div style={{
                                    display : 'flex',
                                    justifyContent : 'center',
                                    // alignItems : 'center'
                                }}>
                                    <VideoContent width='100%' height='250px' src={video.fileUrl} />
                                </div>))
                            }
                        </Box>
                    ) : (
                        <>
                            {loading ? (
                                <CustomCircleLoader width={60} />
                            ) : (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                                    {
                                        dataRows.map((video: any) => {
                                            return (
                                            <div key={video.id}>
                                                <VideoCardItem
                                                    moduleId=''
                                                    fileName={video.fileName}
                                                    originalName={video.originalName}
                                                    category={video.category}
                                                    description={video.description}
                                                    id={video.id}
                                                    title={video.title}
                                                    photo={video.photo}
                                                    readOnly={readOnly}
                                                    setReloadVideo={setReloadVideo}
                                                    fileUrl={video.fileUrl}
                                                />
                                            </div>
                                        )})
                                    }
                                </Box>
                            )}
                        </>
                    )}
                </>
            </div>
        </Box>
    );
};
