import { AttachFile } from '@mui/icons-material';
import { Box, Grid, IconButton, TextareaAutosize } from '@mui/material';
import { ChangeEvent, createRef, FC, useContext, useLayoutEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { Document } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from './CustomModal';
import { useMutation } from '@apollo/client';
import { ADD_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ALLOWED_EXTENSION, TOOLBOX_PDF_CATEGORY } from 'src/utils/constants/constants';
import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface AttachFileModalProps {
    modalDocVisible: boolean;
    toggleModal?: any;
    setUpdateList?: any;
    refetch: any;
}

const defaultValue: Document = { name: '', fileContent: '' };
const defaulDoctype: string = 'application/pdf';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        borderRadius: '10px',
        padding: '15px',
        boxSizing: 'border-box',
        marginTop: 10,
        '&:focus': {
            borderColor: baseColors.green.primary, // Change the color to your desired focus color
            outline: 'none', // Remove the default blue outline
        },
        '&::placeholder': {
            color: '#CCCCCC', // Set the color of the placeholder text
            fontSize: 11,
            fontFamily: baseFontFamily.OpenSansRegular,
        },
    },
}));

const AttachFileModal: FC<AttachFileModalProps> = (props) => {
    const { modalDocVisible, toggleModal, setUpdateList, refetch } = props;
    const [docName, setDocName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [addingNewDoc, setAddingNewDoc] = useState<boolean>(false);
    const documentRef = createRef<HTMLInputElement>();
    const [docType, setDocType] = useState<string>(defaulDoctype);
    const [document, setDocument] = useState<Document>(defaultValue);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [isValid, setIsValid] = useState<boolean>(false);
    const classes = useStyles();
    let docsType: any;
    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            if (!ALLOWED_EXTENSION.includes(file.type)) {
                showErrorSnackBar(
                    'documents non supporté (veuiller importe des fichier .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .csv, .txt, .pptx',
                );
                setDocName('');
            } else {
                setDocName(file.name);
                setDocType(file.type);
                docsType = file.type;
                const reader = new FileReader();
                reader.onload = _handleDocReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };
    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docsType + ';base64,' + btoa(binaryString);
        setDocument((old: Document) => ({ ...old, fileContent }));
    };

    const clearInput = () => {
        setTitle('');
        setDescription('');
        setDocument(defaultValue);
        setDocName('');
        /*  setDocType(defaulDoctype); */
    };

    const [saveToolBox, { loading, error, data }] = useMutation(ADD_TOOL_BOX_MUTATION, {
        onCompleted: () => {
            showSuccessSnackBar('Document ajouté avec succès');
            setAddingNewDoc(false);
            clearInput();
            toggleModal();
            refetch();
        },
        onError: () => {
            setAddingNewDoc(false);
        },
    });

    useLayoutEffect(() => {
        setIsValid(title != '' && description != '' && docName != '');
    }, [title, description, docName]);

    return (
        <CustomModal
            width={500}
            title="Nouvel outil"
            open={modalDocVisible ?? false}
            toggleModal={toggleModal}
            height={512}
        >
            <Box p={'25px'}>
                <Box>
                    <CustomText>Titre du module :</CustomText>
                    <InputField
                        label={''}
                        onChange={(value: any) => {
                            setTitle(value);
                        }}
                        validator={isNotBlank}
                        errorMessage="Titre"
                        placeholder={'Titre du module'}
                        width="100%"
                        height="40px"
                        value={title}
                        idSuffix="titile_module"
                    />
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <CustomText>Importer le fichier :</CustomText>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => documentRef.current?.click()}>
                            <AttachFile />
                        </IconButton>
                        <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                        <CustomText fontSize={11} color={baseColors.green.primary}>
                            {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                        </CustomText>
                    </Box>
                </Box>
                <Box pt="25px">
                    <CustomText>Description :</CustomText>
                    <TextareaAutosize
                        className={classes.input}
                        style={{ height: '200px' }}
                        value={description}
                        placeholder={'Déscritpion du module'}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            setDescription(e.target.value);
                        }}
                    />
                </Box>
                <Box display='flex' justifyContent='center' mt='25px'>
                    {loading === true ? (
                        <CustomCircleLoader width={30} />
                    ) : (
                        <CustomLoadingButton
                            disabled={!isValid}
                            handleClick={() => {
                                if (!isValid) return;
                                setAddingNewDoc(true);
                                saveToolBox({
                                    variables: {
                                        title: title,
                                        description: description,
                                        fileContent: document.fileContent,
                                        fileName: '',
                                        category: TOOLBOX_PDF_CATEGORY
                                    },
                                });
                            }}
                            loading={addingNewDoc}
                            width={300}
                            label={'ENREGISTRER'}
                        />
                    )}
                </Box>
            </Box>
        </CustomModal>
    );
};

export default AttachFileModal;