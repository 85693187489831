import { useContext, useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { UserFormType } from './User.types';
import { baseColors } from 'src/utils/constants/baseStyles';
import InputSelectGender from './InputSelectGender';
import InputSelectPromotion from './InputSelectPromotion';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_ALL_PROMOTIONS } from 'src/graphql/queries/queries';
import { UPDATE_USER } from 'src/graphql/mutations/user.mutation';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ReloadingContext } from 'src/providers/ReloadingProvider';

const UpdateUser = (props: any) => {
    const { isOpened, setIsOpened, user } = props;
    const [inputUser, setInputUser] = useState<any>({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        gender: user.gender,
        roles: user.roles,
        promotionId: user.promotion?.id,
        photoProfilContent: '',
        id: user.id,
    });
    const [promotionList, setPromotionList] = useState<any[]>([]);
    const { loading, data: dataPromotionListQuery, error } = useQuery(LIST_ALL_PROMOTIONS);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const { setIsReload } = useContext(ReloadingContext);

    const [updateUser, { loading: updateLoading, data: dataloading }] = useMutation(UPDATE_USER, {
        onCompleted: (data: any) => {
            showSuccessSnackBar("Modification d'utilisateur réussie");
            setIsReload(true);
            setIsOpened(false);
        },
        onError: (error: any) => {
            console.error(error);
            showErrorSnackBar('Echec de la modification');
        },
    });

    useEffect(() => {
        if (dataPromotionListQuery) {
            setPromotionList((oldstate: any[]) => {
                return dataPromotionListQuery.getAllPromotion.map((promotion: any) => {
                    return {
                        id: promotion.id,
                        name: promotion.name,
                    };
                });
            });
        }
    }, [dataPromotionListQuery]);

    useEffect(() => {
        const userSetting = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            gender: user.gender,
            roles: user.roles,
            promotionId: user.promotion?.id,
            photoProfilContent: '',
            id: user.id,
        } as UserFormType;
        setInputUser(userSetting);
    }, [user]);

    const isUserStudent = () => {
        return inputUser.roles.includes('STUDENT');
    };

    const renderInput = (key: string, label: string) => {
        return (
            <Grid container alignItems="center">
                <Grid item md={2} sm={12}>
                    <Typography fontSize={12}>{label} :</Typography>
                </Grid>
                <Grid item md={10} sm={12}>
                    <TextField
                        fullWidth
                        value={inputUser[key as keyof UserFormType]}
                        onChange={(value) => {
                            handleChange(key, value.target.value);
                        }}
                        InputProps={{
                            style: { fontSize: '13px' }, // Adjust the fontSize value as per your requirement
                        }}
                    ></TextField>
                </Grid>
            </Grid>
        );
    };

    const handleChange = (key: string, value: any) => {
        setInputUser((oldValue: any) => {
            return {
                ...oldValue,
                [key]: value,
            };
        });
    };

    return (
        <CustomModal width="40vw" open={isOpened} toggleModal={setIsOpened} title={`Modification Utilisateur`}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    paddingRight: '2em',
                    paddingLeft: '2em',
                }}
            >
                <Grid container alignItems="center">
                    {renderInput('lastName', 'Nom')}
                </Grid>
                <Grid container alignItems="center">
                    {renderInput('firstName', 'Prénom')}
                </Grid>
                <Grid container alignItems="center">
                    {renderInput('email', 'Email')}
                </Grid>
                <InputSelectGender
                    currentValue={inputUser.gender}
                    onChange={(value: any) => {
                        handleChange('gender', value.target.value);
                    }}
                />
                {isUserStudent() && (
                    <InputSelectPromotion
                        promotionList={promotionList}
                        onChange={(value: any) => {
                            handleChange('promotionId', value.target.value);
                        }}
                        currentValue={inputUser.promotionId}
                    />
                )}
            </Box>

            <Box display="flex" justifyContent="center" mt={3} gap={2}>
                <CustomButton
                    width="150px"
                    background={baseColors.grey}
                    color={baseColors.white}
                    label="Annuler"
                    onClick={() => {
                        setIsOpened(false);
                    }}
                    fontSize={14}
                    lower={true}
                />
                <CustomButton
                    width="150px"
                    label="Enregistrer"
                    onClick={() => {
                        const userData = {
                            firstName: inputUser.firstName,
                            lastName: inputUser.lastName,
                            email: inputUser.email,
                            sex: inputUser.gender,
                            promotionId: inputUser.promotionId,
                            userId: inputUser.id,
                        };
                        updateUser({
                            variables: { userData },
                        });
                    }}
                    loading={updateLoading}
                    fontSize={14}
                    lower={true}
                />
            </Box>
        </CustomModal>
    );
};

export default UpdateUser;
