import { Box } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import { toolboxTabMenus } from 'src/utils/constants/constants';
import ModuleDetailViewModel from './ModuleDetailViewModel';
import ModuleDetailHandler from './ModuleDetailHandler';

type Props = {
    selectedPanel: string;
    setSelectedPanel: Dispatch<SetStateAction<string>>
}

const TooboxTab = (props: Props) => {
    const {selectedPanel, setSelectedPanel} = props
    const vm = new ModuleDetailViewModel();
    const handler = new ModuleDetailHandler();
    const styles = vm.getStyles();
    
    let tabMenuItems = toolboxTabMenus;

    return (
        <Box sx={{...styles.detailTab}}>
            <Box sx={{...styles.tabMenuContainer, marginTop: '20px'}}>
                {handler.handleTabMenu({ tabMenuItems, selectedPanel, setSelectedPanel })}
            </Box>
            <Box sx={styles.separator}></Box>
        </Box>
    );
};

export default TooboxTab;
