import { FC, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { VideoContent } from 'src/pages/Home/Content/Module/VideoContent';
import { Box, Typography } from '@mui/material';

import { EditVideo } from './EditVideo';
import useStyles from 'src/components/Cards/styles/studentCardItemStyles';
import { CustomText } from 'src/components/Text/CustomText';
import { VideoCardItemProps } from 'src/components/Cards/Interface/VideoCardItemProps';
import EditIconButton from 'src/components/Icon/EditIconButton';
import { Spacer } from 'src/components/Spacer/Spacer';
import DeleteIconButton from 'src/components/Icon/DeleteIconButton';
import { DeleteVideo } from './DeleteVideo';
import DeleteToolBoxModal from '../DeleteToolBoxModal';
import { current } from '@reduxjs/toolkit';

export const VideoCardItem: FC<VideoCardItemProps> = (props) => {
    const title = props.title;
    const description = props.description;
    const category = props.category;
    const fileName = props.fileName;
    const originalName = props.originalName;
    const setReloadVideo = props.setReloadVideo;
    const fileUrl = props.fileUrl;

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const toggleDeleteModal = () => setOpenDelete(prev => !prev);

    const toolBoxData = {
        id: props.id,
        title,
        description,
        category,
        fileName,
        originalName
    }

    return (
        <Box sx={{ width: '100%' }}>
            <EditVideo
                toolBoxData={toolBoxData}
                open={openEdit}
                setOpenModal={setOpenEdit}
                setReloadVideo={setReloadVideo}
            />

            <DeleteVideo open={openDelete} setOpenModal={setOpenDelete} toolbonxId={props.id} category={category} />
            
            <DeleteToolBoxModal
                refetch={setReloadVideo}
                modalDocVisible={openDelete}
                toggleModal={toggleDeleteModal}
                id={props.id}
            />

            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Box display="flex" alignItems="center">
                    <Box sx={{display: 'flex', flexDirection:'column'}}>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.green.cancel}
                            fontSize={14}
                        >
                            {description}
                        </Typography>
                        <VideoContent width={'360px'} height={'200px'} controls={true} src={fileUrl} />
                        <Box display="flex" alignItems="center" sx={{position: 'absolute', top: '10px', right: '10px'}}>
                            <Box
                                sx={{cursor: 'pointer'}}
                                onClick={() => {
                                    setOpenEdit(true);
                                }}
                            >
                                <EditIconButton size='25' />
                            </Box>
                            <Spacer width={'10px'} />
                            <Box
                                sx={{cursor: 'pointer'}}
                                onClick={() => {
                                    setOpenDelete(true);
                                }}
                            >
                                <DeleteIconButton size='25' />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
