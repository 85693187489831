class LoginViewModel {
    private styles = {
        loginContainer: {
            backgroundColor: 'white',
            padding: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100%',
            // marginTop : '20%',
            width: '100vw',
            borderRadius : '20px',
            boxSizing: 'border-box',
        },
        loginContent: {
            backgroundColor: 'white',
            height: '100%',
            maxHeight: '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
        },
        loginIconContainer: {
            backgroundColor: 'white',
            margin: 'auto',
        },
        loginTitleContainer: {
            backgroundColor: 'white',
            margin: 'auto',
        },
        loginTitle: {
            marginY: '25px',
            fontSize: '15pt',
            textAlign: 'center',
        },
        usernameContainer: {
            backgroundColor: 'white',
            marginX: 'auto',
        },
        passwordContainer: {
            backgroundColor: 'white',
            marginX: 'auto',
            paddingY: 0,
        },
        submitLoginContainer: {
            marginX: 'auto',
        },
        loginError: {
            fontSize: '10pt',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
        loginErrorContainer: {
            backgroundColor: 'white',
            marginX: 'auto',
            marginY: '10px',
        },
        forgetPasswordContainer: {
            backgroundColor: 'white',
            marginX: 'auto',
            marginY: '25px',
        },
        forgetPasswordLink: {
            fontSize: '11pt',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    };

    public getStyles() {
        return this.styles;
    }

    public saveToken(token: string) {
        localStorage.setItem('token', token);
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public hasToken() {
        return this.getToken() ? true : false;
    }

    public isLoggedIn() {
        return this.hasToken();
    }
    public changeUsername(value: any, state: any) {
        state.setInput({ ...state.input, username: value });
    }

    public changePassword(value: any, state: any) {
        state.setInput({ ...state.input, password: value });
    }
}

export default LoginViewModel;
