import { Box } from '@mui/material';
import { VideoCardList } from 'src/components/Cards/VideoCardList';
import { LIST_VIDEO_BY_MODULE } from 'src/graphql/queries/queries';
import { useQuery } from '@apollo/client';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { useContext, useEffect, useState } from 'react';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import disableSoumettreButtonHandler from '../../Helpers/SoumettreModuleHelper';
import { TYPE_VIDEO_ANNOTEE, TYPE_VIDEO_EXPLICATIVE } from 'src/interfaces/interfaces';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { VideoContent } from 'src/pages/Home/Content/Module/VideoContent';

export const ListVideo = (props: any) => {
    const { module, reload, setReloadVideo, readOnly } = props;
    const moduleId = module?.id;
    const [videoList, setVideoList] = useState<any[]>([]);
    const { setDisableSoumettreButton } = useContext(ModuleDetailContext);

    useEffect(() => {
        if (setDisableSoumettreButton !== undefined) {
            disableSoumettreButtonHandler(module, setDisableSoumettreButton);
        }
        // update video List
        if (module) {
            let videos: any[] = [];
            if (module.video) {
                let videoData: any = {
                    id: module.video.id,
                    title: module.video.title,
                    description: module.video.description || '',
                    fileName: module.video.fileName || '',
                    originalName: module.video.originalName || '',
                    category: module.video.category || TYPE_VIDEO_EXPLICATIVE,
                    fileUrl: module.video.fileUrl,
                };
                videos.push(videoData);
            }
            if (module.videoAnnote) {
                let videoData: any = {
                    id: module.videoAnnote.id,
                    title: module.videoAnnote.title,
                    description: module.videoAnnote.description || '',
                    fileName: module.videoAnnote.fileName || '',
                    originalName: module.videoAnnote.originalName || '',
                    category: module.videoAnnote.category || TYPE_VIDEO_ANNOTEE,
                    fileUrl: module.videoAnnote.fileUrl,
                };
                videos.push(videoData);
            }
            setVideoList(videos);
        }
    }, [module]);
    return (
        <Box
            style={{
                height: 'auto',
                // backgroundColor : 'blue',
            }}
        >
            {readOnly ? (
                <Box display="flex" justifyContent="space-evenly" >
                    {videoList.map((video, index) => (
                        <>
                            <Box width="95%">
                                <CustomText fontFamily={baseFontFamily.OpenSansBold} fontSize={14}>
                                    {video.title}
                                </CustomText>
                                <Spacer height={15} />
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        backgroundColor: 'rgba(230, 244, 244, 0.9)',
                                        boxSizing: 'border-box',
                                        borderRadius: '15px',
                                    }}
                                >
                                    <VideoContent
                                        width="100%"
                                        height="230px"
                                        src={video.fileUrl}
                                        isOnModule={true}
                                        x="-50"
                                        y="-50"
                                    />
                                </div>
                            </Box>
                            {index === 0 ? <Spacer width={25}></Spacer> : ''}
                        </>
                    ))}
                </Box>
            ) : (
                <VideoCardList
                    setReloadVideo={setReloadVideo}
                    moduleId={moduleId}
                    dataRows={videoList}
                    readOnly={readOnly}
                ></VideoCardList>
            )}
        </Box>
    );
};
