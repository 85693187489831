import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { AuthorizationCode, TOOLBOX_VIDEO_CATEGORY } from 'src/utils/constants/constants';
import { Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { PdfIcon } from 'src/components/Icon/PdfIcon';
import { DownloadActionButton } from './DownloadActionButton';
import { useQuery } from '@apollo/client';
import { LIST_TOOL_BOXS } from 'src/graphql/queries/toolbox.queries';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { EditActionButton } from './EditActionButton';
import { DeleteActionButton } from './DeleteActionButton';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import AttachFileModal from './AttachFileModal';
import EditToolBoxModal from './EditToolBoxModal';
import DeleteToolBoxModal from './DeleteToolBoxModal';

type Props = {
    isShowVideo: boolean | undefined
}

const DocumentContent = (props: Props) => {
    const {isShowVideo} = props;
    const viewModel = new ContentPanelViewModel();
    const [toolboxList, setToolboxList] = useState<any[]>([]);
    const styles = viewModel.getStyles();
    const [modalDocVisible, setModalDocVisible] = useState(false);
    const toggleModal = () => setModalDocVisible(!modalDocVisible);
    const [modalDocVisible2, setModalDocVisible2] = useState(false);
    const toggleModal2 = () => setModalDocVisible2(!modalDocVisible2);

    const [modalDocVisible3, setModalDocVisible3] = useState(false);
    const toggleModal3 = () => setModalDocVisible3(!modalDocVisible3);

    const [description, setDescription] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [originalName, setOriginalName] = useState<string>('');

    const { loading, data: dataToolBoxListQuery, error, refetch } = useQuery(LIST_TOOL_BOXS);

    const { codes } = useAuthorizationContext();
    const isAuthorizedToSeeAddToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_CREATION);

    const isAuthorizedToSeeUpdateToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_EDITION);

    const isAuthorizedToSeeDeleteToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_ITEM_DELETION);

    useEffect(() => {
        if (dataToolBoxListQuery?.listToolBox) {
            let data = dataToolBoxListQuery.listToolBox;
            setToolboxList((oldstate: any[]) => {
                const documents = data.filter((toolbox: any) => toolbox.category !== TOOLBOX_VIDEO_CATEGORY)
                return documents?.map((toolbox: any) => {
                    return {
                        id: toolbox.id,
                        title: toolbox.title,   
                        description: toolbox.description,
                        fileName: toolbox.title,
                        originalName: toolbox.originalName,
                        category: toolbox.category,
                        filePath: toolbox.filePath,
                        fileUrl: toolbox.fileUrl,
                        mimeType: toolbox.mimeType,
                    };
                });
            });
        }
    }, [dataToolBoxListQuery]);

    return (
        <Grid container spacing={1} sx={{ width: '100%' }}>
            <Grid style={{ height: '100%', padding: '0 20px', width: '100%' }}>
                <div style={{ padding: '25px 25px 25px 25px', flexBasis: '50%' }}>
                    {!isShowVideo && 
                        <label htmlFor="cars" style={{ lineHeight: '2.5' }}>
                            <Typography
                                fontFamily={baseFontFamily.BreeSerif}
                                fontWeight={400}
                                color={baseColors.green.cancel}
                                fontSize={16}
                            >
                                Outils
                            </Typography>
                        </label> 
                    }
                    {isAuthorizedToSeeAddToolBox && (
                        <>
                            <span style={{ float: 'right' }}>
                                <CustomButton
                                    onClick={() => {
                                        setModalDocVisible(true);
                                    }}
                                    width={'auto'}
                                    label={'Ajouter un outil'}
                                    fontSize={14}
                                    lower={true}
                                />
                            </span>
                        </>
                    )}


                </div>
                {loading ? (
                    <CustomCircleLoader></CustomCircleLoader>
                ) : (
                    <TableContainer
                        component={Paper}
                        style={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)', width: '100%' }}
                    >
                        <Table sx={{ minWidth: 650 }} aria-label="role toolbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.green.cancel}
                                            fontSize={11}
                                        >
                                            Nom
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.green.cancel}
                                            fontSize={11}
                                        >
                                            Description
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.green.cancel}
                                            fontSize={11}
                                        >
                                            Fichier
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.green.cancel}
                                            fontSize={11}
                                        >
                                            Action
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {toolboxList.map((toolbox: any) => {
                                    return (
                                        <TableRow
                                            key={toolbox.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" align="center" scope="row">
                                                <Typography
                                                    fontFamily={baseFontFamily.OpenSansRegular}
                                                    color={baseColors.green.cancel}
                                                    fontSize={11}
                                                >
                                                    {toolbox.title}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="row">
                                                <Typography
                                                    fontFamily={baseFontFamily.OpenSansRegular}
                                                    color={baseColors.green.cancel}
                                                    fontSize={11}
                                                >
                                                    {toolbox.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="row">
                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <PdfIcon width="26px" height="26px" />
                                                    &nbsp;{' '}
                                                    <Typography
                                                        fontFamily={baseFontFamily.OpenSansRegular}
                                                        color={baseColors.green.cancel}
                                                        fontSize={11}
                                                    >
                                                        {toolbox.originalName}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="row">
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="right"
                                                    color="red"
                                                >
                                                    <a
                                                        href={`${toolbox.fileUrl}`}
                                                        download
                                                        style={{ marginRight: '10px', textDecoration: 'none' }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <DownloadActionButton onClick={() => { }} />
                                                    </a>
                                                    {isAuthorizedToSeeUpdateToolBox && (
                                                        <EditActionButton
                                                            onClick={() => {
                                                                setId(toolbox.id);
                                                                setTitle(toolbox.fileName);
                                                                setDescription(toolbox.description);
                                                                setOriginalName(toolbox.originalName);
                                                                setModalDocVisible2(true);
                                                            }}
                                                        />
                                                    )}
                                                    {isAuthorizedToSeeDeleteToolBox && (
                                                        <DeleteActionButton
                                                            onClick={() => {
                                                                setModalDocVisible3(true);
                                                                setId(toolbox.id);
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <AttachFileModal
                                    refetch={refetch}
                                    modalDocVisible={modalDocVisible}
                                    toggleModal={toggleModal}
                                />
                                <EditToolBoxModal
                                    refetch={refetch}
                                    modalDocVisible={modalDocVisible2}
                                    toggleModal={toggleModal2}
                                    id={id}
                                    title={title}
                                    description={description}
                                    originalName={originalName}
                                />
                                <DeleteToolBoxModal
                                    refetch={refetch}
                                    modalDocVisible={modalDocVisible3}
                                    toggleModal={toggleModal3}
                                    id={id}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
};

export default DocumentContent;
