import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module } from 'src/interfaces/interfaces';
import { RootState } from 'src/redux/store';

export interface IListModuleState {
    modules: Module[];
    selected: boolean;
    currentModule: Module;
    currentModuleId: String | null,
    currentModuleType: String;
}

const emptyModule = {
    id: '',
    title: '',
    description: '',
    video: null,
    moduleIsValidated: false,
    videoAnnote: null,
    quizz: [],
    type: '',
    moduleType: '',
}

const initialState: IListModuleState = {
    modules: [],
    selected: false,
    currentModuleId: null,
    currentModule: emptyModule,
    currentModuleType: '',
};

export const listModulesSlice = createSlice({
    name: 'listModule',
    initialState,
    reducers: {
        setCurrentModuleId: (state: IListModuleState, action: PayloadAction<String|null>) => {
            state.currentModuleId = action.payload;
        },
        setListModules: (state: IListModuleState, action: PayloadAction<Module[]>) => {
            state.modules = action.payload;
        },
        addModule: (state: IListModuleState, action: PayloadAction<Module>) => {
            state.modules.push(action.payload);
        },
        deleteModule: (state: IListModuleState, action: PayloadAction<String>) => {
            state.modules = state.modules.filter((value: any) => value.id !== action.payload);
            if (state.modules.length > 0) {
                state.currentModule = state.modules[state.modules.length - 1];
                state.currentModuleId = state.currentModule.id;
            } else {
                state.currentModule = initialState.currentModule;
            }
            // state.currentModule = state.modules[state.modules.length - 1];
        },
        updateModule: (state: IListModuleState, action: PayloadAction<{ moduleId: String; newValue: Module }>) => {
            const { moduleId, newValue } = action.payload;
            const index = state.modules.findIndex((value: any) => value.id === moduleId);
            if (index !== -1) {
                state.modules[index] = newValue;
            }
        },
        setCurrentModule: (state: IListModuleState, action: PayloadAction<Module>) => {
            state.currentModule = action.payload;
            state.selected = true;
            state.currentModuleId = state.currentModule.id;
        },
        createNewModule: (state: IListModuleState) => {
            state.currentModule = emptyModule;
            state.currentModuleId = null;
            state.selected = false;
        },
        setFirstModule: (state: IListModuleState) => {
            if (state.modules.length > 0) {
                state.currentModule = state.modules[0];
                state.currentModuleId = state.currentModule.id
            } else {
                state.currentModule = emptyModule;
                state.currentModuleId = initialState.currentModuleId;
            }
        },
    },
});
export const selectCurrentModuleWithId = (state: RootState): Module => state.listModulesReducer.currentModule;
export const selectCurrentModule = (state: RootState): Module => {
    const currentModule = state.listModulesReducer.modules.find((module: Module) => {
        return module.id === state.listModulesReducer.currentModuleId;
    });
    return currentModule || emptyModule;
}
export const selectSelected = (state: RootState): boolean => state.listModulesReducer.selected;
export const selectLastModule = (state: RootState): Module => {
    const moduleLength = state.listModulesReducer.modules.length
    if (moduleLength >= 1)
        return state.listModulesReducer.modules[moduleLength - 1];
    else
        return emptyModule;
}

export const {
    setListModules,
    addModule,
    deleteModule,
    updateModule,
    setCurrentModule,
    createNewModule,
    setFirstModule,
    setCurrentModuleId,
} = listModulesSlice.actions;

export default listModulesSlice.reducer;
