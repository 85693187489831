const dateTimeFormat = 'DD-MM-YYYY HH:mm';

export { dateTimeFormat };

export const SIDEBAR_MENU_KEY = 'sidebar_menu';

export enum MenuIconName {
    DASHBOARD = 'DASHBOARD',
    SELECTED_DASHBOARD = 'SELECTED_DASHBOARD',
    BILAN = 'BILAN',
    PREREQUISITES = 'PREREQUISITES',
    SELECTED_BILAN = 'SELECTED_BILAN',
    WORKSHOP = 'WORKSHOP',
    SELECTED_WORKSHOP = 'SELECTED_WORKSHOP',
    TOOL_BOX = 'TOOL_BOX',
    SELECTED_TOOL_BOX = 'SELECTED_TOOL_BOX',
    STUDENT_PROGRESS = 'STUDENT_PROGRESS',
    STUDENT_LIST = 'STUDENT_LIST',
    USER_CONSENT = 'USER_CONSENT',
    SETTING = 'SETTING',
    SELECTED_SETTING = 'SELECTED_SETTING',
    USER = 'USER',
    SELECTED_USER = 'SELECTED_USER',
}

export enum AuthorizationCode {
    // Sidebar
    FRONT_DISPLAY_DASHBOARD_SIDEBAR_MENU = 'front_display_dashboard_sidebar_menu',
    FRONT_DISPLAY_EDUCATIVE_BILAN_SIDEBAR_MENU = 'front_display_educative_bilan_sidebar_menu',
    FRONT_DISPLAY_PREREQUISITES_SIDEBAR_MENU = 'front_display_prerequisites_sidebar_menu',
    FRONT_DISPLAY_EDUCATIVE_WORKSHOP_SIDEBAR_MENU = 'front_display_educative_workshop_sidebar_menu',
    FRONT_DISPLAY_TOOL_BOX_SIDEBAR_MENU = 'front_display_toolbox_sidebar_menu',
    FRONT_DISPLAY_STUDENT_PROGRESS_SIDEBAR_MENU = 'front_display_student_progress_sidebar_menu',
    FRONT_DISPLAY_STUDENT_LIST_SIDEBAR_MENU = 'front_display_sudent_list_sidebar_menu',
    FRONT_DISPLAY_USER_CONSENT_SIDEBAR_MENU = 'front_display_user_consent_sidebar_menu',
    FRONT_DISPLAY_PARAMETER_SIDEBAR_MENU = 'front_display_parameter_sidebar_menu',
    FRONT_DISPLAY_PARAMETER_USERS = 'front_display_parameter_users',
    FRONT_DISPLAY_SECURITY_SIDEBAR_MENU = 'front_display_security_sidebar_menu',
    FRONT_DISPLAY_SETTING_SIDEBAR_MENU = 'front_display_setting_sidebar_menu',
    // Toolbox
    FRONT_DISPLAY_TOOL_BOX_LIST = 'front_display_tool_box_list',
    FRONT_DISPLAY_TOOL_BOX_CREATION = 'front_display_tool_box_creation',
    FRONT_DISPLAY_TOOL_BOX_EDITION = 'front_display_tool_box_edition',
    FRONT_DISPLAY_TOOL_BOX_ITEM_DELETION = 'front_display_tool_box_item_edition',
    // Dashboard student
    FRONT_DISPLAY_STUDENT_GLOBAL_PROGRESS = 'front_display_student_global_progress',
    FRONT_DISPLAY_PROMOTION_GLOBAL_PROGRESS = 'front_display_promotion_global_progress',
    FRONT_DISPLAY_EDUCATIVE_BILAN_FIRST_VIDEO = 'front_display_educative_bilan_first_video',
    FRONT_DISPLAY_EDUCATIVE_BILAN_MODULE_DETAIL = 'front_display_educative_bilan_module_detail',
    FRONT_DISPLAY_EXPLICATIVE_VIDEO = 'front_display_explicative_video',
    FRONT_PLAY_EXPLICATIVE_VIDEO = 'front_play_explicative_video',
    // Evaluation
    FRONT_DISPLAY_EVALUATION = 'front_display_evaluation',
    // Activate User
    FRONT_ACTIVATION_USER = 'front_activation_user',
    FRONT_ACTIVATION_STUDENT = 'front_activation_student',
    FRONT_ACTIVATION_PROFESSOR = 'front_activation_professor',
    FRONT_ACTIVATION_ADMIN = 'front_activation_admin',
}
export const menuItems = [
    {
        id: 1,
        icon: MenuIconName.DASHBOARD,
        title: 'Tableau de bord',
        selected: true,
        code: AuthorizationCode.FRONT_DISPLAY_DASHBOARD_SIDEBAR_MENU,
    },
    {
        id: 2,
        icon: MenuIconName.PREREQUISITES,
        title: 'Prérequis',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_PREREQUISITES_SIDEBAR_MENU,
    },
    {
        id: 3,
        icon: MenuIconName.BILAN,
        title: 'Bilan éducatif',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_EDUCATIVE_BILAN_SIDEBAR_MENU,
    },
    {
        id: 4,
        icon: MenuIconName.WORKSHOP,
        title: 'Atelier éducatif',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_EDUCATIVE_WORKSHOP_SIDEBAR_MENU,
    },
    {
        id: 5,
        icon: MenuIconName.TOOL_BOX,
        title: 'Boite à outils',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_SIDEBAR_MENU,
    },
    //     {
    //         id: 5,
    //         icon: MenuIconName.STUDENT_PROGRESS,
    //         title: 'Votre progression',
    //         selected: false,
    //         code: AuthorizationCode.FRONT_DISPLAY_STUDENT_PROGRESS_SIDEBAR_MENU,
    //     },
    //     {
    //         id: 6,
    //         icon: MenuIconName.STUDENT_LIST,
    //         title: 'Liste des etudiants',
    //         selected: false,
    //         code: AuthorizationCode.FRONT_DISPLAY_STUDENT_LIST_SIDEBAR_MENU,
    //     },
    {
        id: 6,
        icon: MenuIconName.USER_CONSENT,
        title: 'Consentement',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_USER_CONSENT_SIDEBAR_MENU,
        hidden: true,
    },
    {
        id: 7,
        icon: MenuIconName.SETTING,
        title: 'Paramètres',
        selected: false,
        code: AuthorizationCode.FRONT_DISPLAY_SETTING_SIDEBAR_MENU,
        hidden: false,
    },
];

export enum Roles {
    STUDENT = 'ROLE_STUDENT',
    USER = 'ROLE_USER',
    ADMIN = 'ROLE_ADMIN',
}

export enum categories {
    STUDENT = 'STUDENT',
    ADMIN = 'ADMIN',
    PROFESSOR = 'PROFESSOR',
    USER = 'USER',
}


export enum BilanTabMenuIconName {
    INFORMATION = 'INFORMATION',
    VIDEO = 'VIDEO',
    QUIZZ = 'QUIZZ',
    OBSERVATIONS = 'OBSERVATIONS',
    EVALUATIONS = 'EVALUATIONS',
}
export const educativeBilanTabMenus = [
    {
        id: 1,
        icon: BilanTabMenuIconName.INFORMATION,
        title: 'Information',
        selected: true,
        showInCreateModule: true,
    },
    {
        id: 2,
        icon: BilanTabMenuIconName.VIDEO,
        title: 'Vidéo',
        selected: false,
        showInCreateModule: true,
    },
    {
        id: 3,
        icon: BilanTabMenuIconName.QUIZZ,
        title: 'Quiz',
        selected: false,
        showInCreateModule: true,
    },
    {
        id: 4,
        icon: BilanTabMenuIconName.OBSERVATIONS,
        title: 'Observations',
        selected: false,
        showInCreateModule: false,
    },
    {
        id: 5,
        icon: BilanTabMenuIconName.EVALUATIONS,
        title: 'Évaluations',
        selected: false,
        showInCreateModule: false,
    },
];

export enum ToolboxTabMenuIconName {
    DOCUMENT = 'DOCUMENT',
    VIDEO = 'VIDEO',
}

export const toolboxTabMenus = [
    {
        id: 1,
        icon: ToolboxTabMenuIconName.DOCUMENT,
        title: 'Outils',
        selected: false,
        showInCreateModule: true,
    },
    {
        id: 2,
        icon: ToolboxTabMenuIconName.VIDEO,
        title: 'Vidéos',
        selected: true,
        showInCreateModule: true,
    }
];

export enum QuestionType {
    TEXT = 'text',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
}

export enum ModuleType {
    EDUCATIVE_BILAN = 'EDUCATIVE BILAN',
    EDUCATIVE_WORKSHOP = 'EDUCATIVE WORKSHOP',
    VIDEO = 'video',
    VIDEO_ANNOTE = 'video_annote',
}

export const ALLOWED_EXTENSION = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/csv',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ALLOWED_PROFILE_IMAGE_EXTENSION = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/x-icon',
    'image/gif',
];
export enum SettingMenuIcon {
    USER = 'USER',
}

export const settingMenuItems = [
    {
        id: 1,
        icon: SettingMenuIcon.USER,
        title: 'Utilisateurs',
        selected: true,
    },
    {
        id: 2,
        icon: SettingMenuIcon.USER,
        title : 'Promotions',
        selected : false,
    }
];

export const Categorie: { [string: string]: string } = {
    ROLE_STUDENT: 'STUDENT',
    ROLE_PROFESSOR: 'PROFESSOR',
    ROLE_ADMIN: 'ADMIN',
};

export const FRONT_EXPIRATION_DELAY = 20 * 60 * 1000;
export const TOOLBOX_VIDEO_CATEGORY = 'TOOLBOX_VIDEO';
export const TOOLBOX_PDF_CATEGORY = 'TOOLBOX_PDF';
