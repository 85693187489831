import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { AuthorizationCode, TOOLBOX_VIDEO_CATEGORY } from 'src/utils/constants/constants';
import { Grid, Typography } from '@mui/material';
import { AddVideo } from './video/AddVideo';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useQuery } from '@apollo/client';
import { LIST_TOOL_BOXS } from 'src/graphql/queries/toolbox.queries';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { VideoCardList } from './video/VideoCardList';

type Props = {

}

const VideoContent = (props: Props) => {
    const [reloadVideo, setReloadVideo] = useState<boolean>(false);
    const [toolboxList, setToolboxList] = useState<any[]>([]);
    
    const { loading, data: dataToolBoxListQuery, error, refetch } = useQuery(LIST_TOOL_BOXS);
    const { codes } = useAuthorizationContext();
    const isAuthorizedToSeeAddToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_CREATION);

    useEffect(() => {
        if (dataToolBoxListQuery?.listToolBox) {
            let data = dataToolBoxListQuery.listToolBox;
            setToolboxList((oldstate: any[]) => {
                const videos = data.filter((toolbox: any) => toolbox.category === TOOLBOX_VIDEO_CATEGORY)

                return videos?.map((toolbox: any) => {
                    return {
                        id: toolbox.id,
                        title: toolbox.title,   
                        description: toolbox.description,
                        fileName: toolbox.fileName,
                        originalName: toolbox.fileName,
                        category: toolbox.category,
                        filePath: toolbox.filePath,
                        fileUrl: toolbox.fileUrl,
                        mimeType: toolbox.mimeType,
                    };
                });
            });
        }
    }, [dataToolBoxListQuery]);

    useEffect(() => {
        refetch()
    },[reloadVideo])

    return (
        <Grid container spacing={1} sx={{ width: '100%' }}>
            <Grid style={{ height: '100%', padding: '0 20px', width: '100%' }}>
                <div style={{ padding: '25px 25px 25px 25px', flexBasis: '50%' }}>
                    {isAuthorizedToSeeAddToolBox && (
                        <Box sx={{ float: 'right' }}>
                            <AddVideo setReloadVideo={setReloadVideo} />
                        </Box>
                    )}
                </div>
                {loading ? (
                    <CustomCircleLoader></CustomCircleLoader>
                ) : isAuthorizedToSeeAddToolBox ? 
                (
                    <VideoCardList
                        setReloadVideo={setReloadVideo}
                        dataRows={toolboxList}
                        readOnly={false}
                        loading={loading}
                    ></VideoCardList>
                ): 
                <Box sx={{display: 'flex',gap: '16px', flexWrap: 'wrap'}}>
                    {
                        toolboxList.map((video: any) => 
                            <Box sx={{display: 'flex', flexDirection:'column', borderRadius: 4} }>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    color={baseColors.green.cancel}
                                    fontSize={14}
                                >
                                    {video.title}
                                </Typography>
                                <video width="360" height="200" controls poster="thumbnail.jpg">
                                    <source src={video.fileUrl} type={video.mimeType}/>
                                    Votre navigateur ne supporte pas la balise vidéo.
                                </video>
                            </Box>
                        )
                    }
                </Box>
            }
            </Grid>
        </Grid>
    );
};

export default VideoContent;
{/* <Video
    onProgress={(value: any) => {}}
    videoFile={video.fileUrl}
    videoUrl={video.fileUrl}
    progressionVideo={0}
></Video> */}