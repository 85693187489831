import { Box, Typography } from '@mui/material';
import { style } from '@mui/system';
import { useEffect, useState } from 'react';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import ModuleDetailViewModel from './ModuleDetailViewModel';

export interface TabMenuItemProps {
    key: number;
    title: string;
    icon?: any;
    selected: boolean;
    onClick: any;
}

const tabMenuItemProps = {
    key: 0,
    title: '',
    selected: false,
    icon: null,
    onClick: () => {},
};
export const TabMenuItem = (props: TabMenuItemProps = tabMenuItemProps) => {
    const vm = new ModuleDetailViewModel();
    const styles = vm.getStyles();
    const { title, selected, icon, onClick } = props;
    const isSelected = selected;

    return (
        <Box sx={styles.tabItem} onClick={() => onClick()}>
            {isSelected ? (
                <Typography
                    fontFamily={baseFontFamily.OpenSansBold}
                    color={baseColors.green.lightPastel}
                    fontSize={14}
                >
                    {title}
                </Typography>
            ) : (
                <Typography
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    color={baseColors.green.cancel}
                    fontSize={14}
                >
                    {title}
                </Typography>
            )}

            <Box
                sx={{
                    ...(isSelected ? styles.indicator : styles.hiddenIndicator),
                    // transition: 'background-color 0.15s',
                }}
            ></Box>
        </Box>
    );
};
