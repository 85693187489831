import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';

interface InputSelectGenderProps {
    currentValue: string;
    onChange: Function;
}

const InputSelectGender = (props: InputSelectGenderProps) => {
    const { currentValue, onChange } = props;
    return (
        <Grid container alignItems="center">
            <Grid item md={2} sm={12}>
                <Typography fontSize={12}>Genre :</Typography>
            </Grid>
            <Grid item md={10} sm={12}>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={currentValue}
                    onChange={(value) => onChange(value)}
                >
                    <FormControlLabel
                        label={<Typography fontSize={12}>Masculin</Typography>}
                        value="MALE"
                        control={<Radio name="genre" />}
                    />
                    <FormControlLabel
                        label={<Typography fontSize={12}>Féminin</Typography>}
                        value="FEMALE"
                        control={<Radio name="genre" />}
                        style={{ fontSize: '12px' }}
                    />
                    {/* <FormControlLabel
                        label={<Typography fontSize={12}>Autres</Typography>}
                        value="other"
                        control={<Radio name="genre" />}
                        style={{ fontSize: '12px' }}
                    /> */}
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

export default InputSelectGender;
