import {
    Modal,
    Box,
    TextField,
    TextareaAutosize,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';

import { ChangeEvent, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomButton } from 'src/components/Button/CustomButton';
import { useMutation } from '@apollo/client';
import { ADD_VIDEO, UPDATE_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { useDispatch } from 'react-redux';
import { Module } from 'src/interfaces/interfaces';
import { setCurrentModule, updateModule } from 'src/redux/features/listModules/listModulesSlice';
import { VideoUploader } from '../../EducativeBilan/TabPanel/VideoPanel/VideoUploader';
import { TOOLBOX_VIDEO_CATEGORY } from 'src/utils/constants/constants';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '70vh',
    minHeight: 335,
    borderRadius: 2,
    bgcolor: 'background.paper',
    p: 4,
};

type Props = {
    toolBoxData: any,
    setOpenModal: Dispatch<SetStateAction<boolean>>,
    open: boolean,
    setReloadVideo: Dispatch<SetStateAction<boolean>>
}

export const EditVideo = (props: Props) => {
    const { toolBoxData,setReloadVideo, setOpenModal, open } = props
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState<string>(toolBoxData.fileName);
    const [originalName, setOriginalName] = useState<string>(toolBoxData.originalName);
    const [title, setTitle] = useState<string>(toolBoxData.title);
    const [category, setCategory] = useState<string>(toolBoxData.category);
    const [description, setDescription] = useState<string>(toolBoxData.description);
    const [isUploaded, setIsUploaded] = useState<boolean>(true);

    const handleClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            setOpenModal(false);
        }
    };

    const [editVideo, { data, loading, error }] = useMutation(UPDATE_TOOL_BOX_MUTATION, {
        onCompleted: (data) => {
            setReloadVideo(prev => !prev);
            setOpenModal(false);
        },
        onError: (error) => {
            alert('add video error');
        },
    });

    const handleSubmit = () => {
        editVideo({ 
            variables: {
                id: toolBoxData.id,
                title: title,
                description: description,
                fileContent: fileName,
                fileName,
                category: TOOLBOX_VIDEO_CATEGORY
            },
        });
    };

    const getFile = (data: any) => {
        if (data.file) {
            setFileName(data.file.uniqueIdentifier + '.' + data.file.name.split('.').pop());
            setOriginalName(data.fileName);
            setIsUploaded(false);
        }
    };

    const getUploadState = (data: any) => {
        setIsUploaded(data);
    };

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const onCategoryChange = (e: SelectChangeEvent<string>) => {
        setCategory(e.target.value);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={16}
                    color={baseColors.black}
                    textAlign={'center'}
                    marginBottom={5}
                >
                    Modification vidéo
                </Typography>
                <div style={{ width: '100%', height: '75%' }}>
                    <div style={{ float: 'left', width: '20%', height: '90%' }}>
                        <div style={{ width: '100%', height: '65px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Titre (<b style={{color: 'red'}}>*</b>) :
                                </Typography>
                            </label>
                        </div>
                        <div style={{ width: '100%', height: '125px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Description (<b style={{color: 'red'}}>*</b>) :
                                </Typography>
                            </label>
                        </div>
                        <div style={{ width: '100%', height: '75px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Importer le fichier :
                                </Typography>
                            </label>
                        </div>
                    </div>
                    <div style={{ float: 'left', width: '80%', height: '90%', marginTop: '-5px' }}>
                        <div style={{ width: '100%', height: '65px' }}>
                            <TextField multiline name="title" value={title} onChange={onTitleChange} fullWidth />
                        </div>
                        <div style={{ width: '100%', height: '125px' }}>
                            <TextareaAutosize
                                style={{ width: '99.5%', height: '90%' }}
                                value={description}
                                onChange={onDescriptionChange}
                            />
                        </div>
                        <div style={{ width: '100%', height: '75px' }}>
                            <div style={{ width: '155px' }}>
                                <VideoUploader
                                    nameOfFile={fileName}
                                    onChange={getFile}
                                    onUploaded={getUploadState}
                                ></VideoUploader>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '75px', textAlign: 'center' }}>
                    <Box style={{ textAlign: 'center' }}>
                        <CustomButton
                            label={' Annuler '}
                            width={150}
                            background={baseColors.green.cancel}
                            color={baseColors.white}
                            onClick={handleClose}
                            marginRight={'8px'}
                        ></CustomButton>
                        {loading === true ? (
                            <CustomCircleLoader width={30} />
                        ) : (
                            <CustomButton
                                label={' Modifier '}
                                width={150}
                                disabled={
                                    !title || !description || !category || !fileName || !isUploaded || !originalName
                                }
                                onClick={handleSubmit}
                            ></CustomButton>
                        )}
                    </Box>
                </div>
            </Box>
        </Modal>
    );
};
