type ModuleProgression = {
    seenVideos: number,
    totalVideos: number,
    filledForm: number,
    totalForm: number,
    readDocuments: number,
    totalDocuments: number
}
type SummaryProgression = {
    student: {
        id: string,
        firstName: string,
        lastName: string,
    },
    educativeBilan: ModuleProgression,
    educativeWorkshop: ModuleProgression,
    prerequisite?: {
        isFinished: boolean,
        fileUrl: string
    }

}
const adaptSummaryProgression = ({ student, educativeBilan, educativeWorkshop, prerequisite }: any) => ({
    student: {
        id: student.id,
        firstName: student.firstName,
        lastName: student.lastName
    },
    educativeBilan: {
        seenVideos: educativeBilan.seenVideos,
        totalVideos: educativeBilan.totalVideos,
        filledForm: educativeBilan.filledForm,
        totalForm: educativeBilan.totalForm,
        readDocuments: educativeBilan.readDocuments,
        totalDocuments: educativeBilan.totalDocuments
    },
    educativeWorkshop: {
        seenVideos: educativeWorkshop.seenVideos,
        totalVideos: educativeWorkshop.totalVideos,
        filledForm: educativeWorkshop.filledForm,
        totalForm: educativeWorkshop.totalForm,
        readDocuments: educativeWorkshop.readDocuments,
        totalDocuments: educativeWorkshop.totalDocuments
    },
    prerequisite:{
        isFinished: prerequisite?.isFinished,
        fileUrl: prerequisite?.fileUrl,
    }
} as SummaryProgression);

export type { ModuleProgression, SummaryProgression }
export { adaptSummaryProgression };