import { Box } from '@mui/system';
import ModuleDetailViewModel from './ModuleDetailViewModel';
import { ProfileImage } from '../Home/Header/ProfileImage';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useContext, useEffect, useState } from 'react';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { useMutation } from '@apollo/client';
import { DELETE_MODULE } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { VALIDATE_MODULE_BY_PROFESSOR } from 'src/graphql/mutations/module.mutations';
import disableSoumettreButtonHandler from './Helpers/SoumettreModuleHelper';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import {
    deleteModule,
    selectCurrentModule,
    setCurrentModule,
    setCurrentModuleId,
    updateModule,
} from 'src/redux/features/listModules/listModulesSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Module } from 'src/interfaces/interfaces';

const ModuleDetailHeader = (props: any) => {
    const dispatch = useDispatch();
    const vm = new ModuleDetailViewModel();
    const styles = vm.getStyles();
    const { isCreatingNewModule, setIsCreatingNewModule } = useContext(ModuleDetailContext);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteModuleMutation, dataMutation] = useMutation(DELETE_MODULE);
    const [validateModuleMutation, validateDataMutation] = useMutation(VALIDATE_MODULE_BY_PROFESSOR);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [showSoumettreModal, setShowSoumettreModal] = useState(false);
    const [disableSoumettreButton, setDisableSoumettreButton] = useState<boolean>(false);
    const [fields, setFields] = useState<Array<String>>();

    const module = useSelector(selectCurrentModule) as any;

    useEffect(() => {
        setDisableSoumettreButton(disableSoumettreButtonHandler(module, undefined));
    }, [module]);

    const deleteModuleFunction = () => {
        setShowConfirmModal(true);
    };
    const soumettreModuleFunction = () => {
        //set moduleIsValidated to true + update userProgression
        if (module !== null) {
            validateModuleMutation({
                variables: { moduleId: module?.id },
                onCompleted: (data: any) => {
                    if (data.validateModuleByProfessor.code === '500') {
                        setFields(data.validateModuleByProfessor.fieldsNotFound);
                        showErrorSnackBar(fields + ' not found ');
                    } else {
                        showSuccessSnackBar();
                        setShowSoumettreModal(false);
                        let updatedModule = { ...module, moduleIsValidated: true, isDeletable : true } as Module;
                        dispatch(updateModule({ moduleId: module.id, newValue: updatedModule }));
                        // dispatch(setCurrentModule(updatedModule));
                        // dispatch(setCurrentModuleId(module.id));
                    }
                },
                onError: (error: any) => {
                    console.error('error ', error);
                    showErrorSnackBar(error);
                },
            });
            setIsCreatingNewModule(false);
        } else {
            showErrorSnackBar('Vous devez ajouter le titre et description du module ! ');
        }
    };

    const confirmDeleteModule = () => {
        deleteModuleMutation({
            variables: { input: { moduleId: module?.id } },
            onCompleted: (data) => {
                if (data.deleteModuleById.code === '200') {
                    showSuccessSnackBar();
                    const moduleId = module?.id;
                    dispatch(deleteModule(moduleId));
                } else {
                    showErrorSnackBar();
                }
            },
            onError: () => {
                showErrorSnackBar();
            },
        });
        setShowConfirmModal(false);
    };

    return (
        <Box
            fontStyle={{
                width: '100%',
            }}
        >
            <Box>
                {isCreatingNewModule && (
                    <Box sx={{ ...styles.detailHeader }}>
                        <Typography fontFamily={baseFontFamily.BreeSerif} fontWeight={'400'} fontSize={18}>
                            Créer un nouveau module
                        </Typography>
                    </Box>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {!isCreatingNewModule && (
                        <Box sx={styles.detailHeader}>
                            <Box sx={styles.moduleImage}>
                                <ProfileImage width="65px" height="65px" alt="M" />
                            </Box>
                            <Box sx={styles.moduleInfos}>
                                <Typography
                                    color={baseColors.green.cancel}
                                    fontFamily={baseFontFamily.BreeSerif}
                                    fontWeight={400}
                                    fontSize={18}
                                >
                                    {module ? module.title : <>Titre du module</>}
                                </Typography>
                                {module?.description && (
                                    <Typography
                                        color={baseColors.green.lightPastel}
                                        fontSize={14}
                                        fontFamily={baseFontFamily.OpenSansRegular}
                                        fontWeight={700}
                                        sx={styles.latestPathology}
                                    >
                                        {module?.description}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}

                    {!isCreatingNewModule && module?.moduleIsValidated && module?.isDeletable && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <>
                                <CustomButton
                                    label="Supprimer"
                                    onClick={deleteModuleFunction}
                                    width={100}
                                    background={baseColors.orange.primary}
                                    color={baseColors.white}
                                    marginRight="20px"
                                    lower={true}
                                    fontSize={12}
                                ></CustomButton>
                            </>
                        </div>
                    )}

                    {!isCreatingNewModule && !module?.moduleIsValidated && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <>
                                <CustomButton
                                    label="Supprimer"
                                    onClick={deleteModuleFunction}
                                    width={100}
                                    background={baseColors.orange.primary}
                                    color={baseColors.white}
                                    marginRight="20px"
                                    lower={true}
                                    fontSize={12}
                                ></CustomButton>

                                {module && (
                                    <CustomButton
                                        label="Soumettre"
                                        marginRight="35px"
                                        onClick={() => setShowSoumettreModal(true)}
                                        width={100}
                                        background={baseColors.green.light}
                                        disabled={disableSoumettreButton}
                                        lower={true}
                                        fontSize={12}
                                    ></CustomButton>
                                )}
                            </>
                        </div>
                    )}
                </div>
                <CustomConfirm
                    modalVisible={showConfirmModal}
                    updateModalVisible={setShowConfirmModal}
                    yesLabel={'Oui'}
                    noLabel={'Non'}
                    message={'Voulez-vous vraiment supprimer ce module?'}
                    disableBackdropClick={false}
                    callBackconfirm={confirmDeleteModule}
                ></CustomConfirm>

                <CustomConfirm
                    modalVisible={showSoumettreModal}
                    updateModalVisible={setShowSoumettreModal}
                    yesLabel={'Oui'}
                    noLabel={'Non'}
                    callBackconfirm={soumettreModuleFunction}
                    callbackCancel={() => setShowSoumettreModal(false)}
                    disableBackdropClick={false}
                    message={'Voulez-vous vraiment soumettre ce module?'}
                />
            </Box>
        </Box>
    );
};

export default ModuleDetailHeader;
