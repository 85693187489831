import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { Spacer } from 'src/components/Spacer/Spacer';
import { baseColors } from 'src/utils/constants/baseStyles';

interface buttonProps {
    addTrameStudentToBackendFunction: Function;
    triggerOk: Function;
    moduleId: string;
    moduleType: string;
    trameInput: any;
}

const EvaluationStudentEducativeBilanButtonAndModals: FC<buttonProps> = (props) => {
    const { addTrameStudentToBackendFunction, moduleId, moduleType, trameInput, triggerOk } = props;

    const [firstModalVisible, setFirstModalVisible] = useState<boolean>(false);
    const [modalConfirmationExportVisible, setModalConfirmationExportVisible] = useState<boolean>(false);
    let exportTrame = false;

    /**WHen the user click on SOUMETTRE button (save+export) */
    const saveAndExportTrame = () => {
        addTrameStudentToBackendFunction({
            variables: { updateTrameInput: { moduleId, moduleType, trameInput, exportTrame } },
        });
        setFirstModalVisible(!firstModalVisible);
    };
    /**WHen the user confirm to upload the trame to pdf (export) */
    const confirmExportTrameToPdf = () => {
        exportTrame = true;
        addTrameStudentToBackendFunction({
            variables: { updateTrameInput: { moduleId, moduleType, exportTrame } },
        });
        setModalConfirmationExportVisible(!modalConfirmationExportVisible);
        setFirstModalVisible(!firstModalVisible);
    };

    const confirmPdfIsExported = () => {
        setModalConfirmationExportVisible(false);
        triggerOk(true);
    };
    return (
        <Box>
            <Spacer height={20}></Spacer>
            <div style={{ display: 'flex', flexDirection: 'row', float : 'right' }}>
                <CustomButton
                    label={' Soumettre '}
                    background={baseColors.green.light}
                    width={150}
                    fontSize={12}
                    lower = {true}
                    onClick={saveAndExportTrame}
                ></CustomButton>
                <Spacer width={25}></Spacer>
                <CustomButton
                    label={'Enregistrer '}
                    width={150}
                    fontSize={12}
                    lower = {true}
                    onClick={() =>
                        addTrameStudentToBackendFunction({
                            variables: { updateTrameInput: { moduleId, moduleType, trameInput, exportTrame } },
                        })
                    }
                ></CustomButton>
            </div>
            <Spacer height={25}></Spacer>

            <CustomConfirm
                modalVisible={firstModalVisible}
                updateModalVisible={setFirstModalVisible}
                yesLabel={'SOUMETTRE'}
                noLabel={'ANNULER'}
                callBackconfirm={confirmExportTrameToPdf}
                disableBackdropClick={false}
                message={
                    "Voulez-vous confirmer l’envoi de votre évaluation? \n A noter que l'envoi du document pdf permet de vous donner accès au support corrigé"
                }
            />
            <CustomConfirm
                modalVisible={modalConfirmationExportVisible}
                updateModalVisible={setModalConfirmationExportVisible}
                yesLabel={'OK'}
                callBackconfirm={confirmPdfIsExported}
                disableBackdropClick={false}
                message={'Evaluation validée et exportée'}
                disableNoButton={true}
                disableYesButton={false}
            />
        </Box>
    );
};

export default EvaluationStudentEducativeBilanButtonAndModals;
