import { gql } from '@apollo/client';
import { VIDEO_FRAGMENT } from '../fragments/fragments';

const LOGIN = gql`
    mutation LoginCheckMutation($email: String!, $password: String!) {
        authentication(email: $email, password: $password) {
            token
            error
        }
    }
`;

const ADD_STUDENT_TRAME = gql`
    mutation updateTrameEducativeBilanOrEducativeWorkshop($updateTrameInput: UpdateTrameInput!) {
        updateTrameEducativeBilanOrEducativeWorkshop(updateTrameInput: $updateTrameInput) {
            message
            code
            status
        }
    }
`;
const LOGOUT = gql`
    mutation logout($token: String!) {
        logout(token: $token) {
            response
        }
    }
`;

const FORGOT_PASSWORD_MUTATION = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email) {
            status
            code
            message
        }
    }
`;

const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($token: String!, $password: String!, $retypePassword: String!) {
        resetPassword(token: $token, password: $password, retypePassword: $retypePassword) {
            status
            code
            message
        }
    }
`;

const ACTIVATE_ACCOUNT_MUTATION = gql`
    mutation activateAccount($token: String!, $password: String!, $retypePassword: String!) {
        activateAccount(token: $token, password: $password, retypePassword: $retypePassword) {
            status
            code
            message
        }
    }
`;

const START_EVALUATION_MUTATION = gql`
    mutation startEvaluation($moduleId: String!, $moduleType: String!) {
        startEvaluation(moduleId: $moduleId, moduleType: $moduleType) {
            status
            code
            message
            moduleType
            moduleId
        }
    }
`;

const ADD_VIDEO = gql`
    ${VIDEO_FRAGMENT}
    mutation addVideo(
        $fileName: String
        $originalName: String
        $category: String
        $title: String!
        $description: String!
        $moduleId: String!
    ) {
        addVideo(
            addVideoInput: {
                fileName: $fileName
                originalName: $originalName
                category: $category
                title: $title
                description: $description
                moduleId: $moduleId
            }
        ) {
            status
            code
            message
            file {
                fileUrl
                fileName
            }
            module {
                id
                title
                description
                video {
                    ...VideoFields
                }
                videoAnnote {
                    ...VideoFields
                }
                moduleIsValidated
                type
                quizz {
                    id
                    type
                    text
                    choices {
                        text
                    }
                }
            }
        }
    }
`;

const DELETE_VIDEO = gql`
    mutation deleteVideo($category: String, $moduleId: String!) {
        deleteVideo(deleteVideoInput: { category: $category, moduleId: $moduleId }) {
            status
            code
            message
        }
    }
`;

const COMPRESS_VIDEO = gql`
    mutation compressUploadedVideo($videoPath: String) {
        compressUploadedVideo(videoPath: $videoPath) {
            message,
            status,
            code
        }
    }
`;

const FINALIZE_EDUCATIVE_BILAN_MUTATION = gql`
    mutation finalize($moduleId: String!) {
        finalize(moduleId: $moduleId) {
            message
            code
            status
        }
    }
`;

const FINALIZE_EDUCATIVE_WORKSHOP_MUTATION = gql`
    mutation finalize($moduleId: String!) {
        finalizeEducativeWorkshop(moduleId: $moduleId) {
            message
            code
            status
        }
    }
`;

const ADD_TOOL_BOX_MUTATION = gql`
    mutation importToolBox($title: String!, $description: String!, $fileContent: String!, $fileName: String!,  $category: String!) {
        importToolBox(toolBoxData: { title: $title, description: $description, fileContent: $fileContent, fileName: $fileName, category: $category }) {
            message
            code
            status
        }
    }
`;

const ADD_PREREQUIS_PDF_MUTATION = gql`
    mutation responsePrerequisPdf($title: String!, $description: String!, $fileContent: String!) {
        responsePrerequisPdf(importPdfResponse: { title: $title, description: $description, fileContent: $fileContent }) {
            message
            code
            status
        }
    }
`;

const IMPORT_USERS = gql`
    mutation importUsers($fileContent: String!) {
        importUsers(fileContent: $fileContent) {
            message
            code
            status
            anomalies {
                email
                message
                code
            }
        }
    }
`;

const UPDATE_MODULE_TITLE_DESCRIPTION = gql`
    mutation updateModule($input: UpdateTitleDescriptionModuleInput!) {
        updateTitleDescriptionOfModule(updateTitleDescriptionModuleInput: $input) {
            message
            code
            updatedModule {
                id
                title
                description
                video {
                    id
                    title
                    description
                    fileUrl
                    filePath
                }
                videoAnnote {
                    id
                    title
                    description
                    fileUrl
                    filePath
                }
                moduleIsValidated
                type
                quizz {
                    id
                    type
                    text
                    choices {
                        text
                    }
                }
            }
        }
    }
`;

const DELETE_TOOL_BOX_MUTATION = gql`
    mutation deleteToolBox($id: String!) {
        deleteToolBox(id: $id) {
            message
            code
            status
        }
    }
`;

const DELETE_MODULE = gql`
    mutation deleteModule($input: DeleteModuleInput!) {
        deleteModuleById(deleteModuleInput: $input) {
            message
            code
        }
    }
`;

const UPDATE_TOOL_BOX_MUTATION = gql`
    mutation updateToolBox($id: String!, $title: String!, $description: String!, $fileContent: String!, $fileName: String!,  $category: String!) {
        updateToolBox(toolBoxData: { id: $id, title: $title, description: $description, fileContent: $fileContent, fileName: $fileName, category: $category }) {
            message
            code
            status
        }
    }
`;

const CREATE_PROMOTION = gql`
    mutation createPromotion($name: String!) {
        createPromotion(name: $name) {
            message
            status
        }
    }
`;

const UPDATE_PROMOTION = gql`
    mutation updatePromotion($id: String!, $name: String!) {
        updatePromotion(id: $id, name: $name) {
            message
            status
        }
    }
`;

const DELETE_PROMOTION = gql`
    mutation deletePromotion($id: String!) {
        deletePromotion(id: $id) {
            message
            status
        }
    }
`;

export {
    ADD_VIDEO,
    DELETE_VIDEO,
    COMPRESS_VIDEO,
    LOGIN,
    ADD_STUDENT_TRAME,
    LOGOUT,
    FORGOT_PASSWORD_MUTATION,
    RESET_PASSWORD_MUTATION,
    ACTIVATE_ACCOUNT_MUTATION,
    START_EVALUATION_MUTATION,
    FINALIZE_EDUCATIVE_BILAN_MUTATION,
    FINALIZE_EDUCATIVE_WORKSHOP_MUTATION,
    ADD_TOOL_BOX_MUTATION,
    ADD_PREREQUIS_PDF_MUTATION,
    UPDATE_MODULE_TITLE_DESCRIPTION,
    DELETE_MODULE,
    UPDATE_TOOL_BOX_MUTATION,
    DELETE_TOOL_BOX_MUTATION,
    IMPORT_USERS,
    CREATE_PROMOTION,
    UPDATE_PROMOTION,
    DELETE_PROMOTION
};
