import { Box } from '@mui/material';
import { useState } from 'react';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { AuthorizationCode, ToolboxTabMenuIconName } from 'src/utils/constants/constants';
import TooboxTab from './ToolboxTab';
import DocumentContent from './DocumentContent';
import VideoContent from './VideoContent';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';

export const ToolBoxPanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    const [ selectedPanel, setSelectedPanel ] = useState<string>(ToolboxTabMenuIconName.DOCUMENT);
    const isDocument = selectedPanel === ToolboxTabMenuIconName.DOCUMENT;
    const isVideo = selectedPanel === ToolboxTabMenuIconName.VIDEO;
    const { codes } = useAuthorizationContext();

    const isShowVideo = codes?.includes(AuthorizationCode.FRONT_DISPLAY_PREREQUISITES_SIDEBAR_MENU) || 
                        codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_CREATION);

    return (
        <>
            { isShowVideo && <TooboxTab selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel} /> }

            <Box sx={{...styles.toolBoxPanel,marginTop: '20px'}}>
                {isDocument ?  
                    <DocumentContent isShowVideo={isShowVideo} /> : (isShowVideo && <VideoContent />)
                }
            </Box>
        </>
    );
};
