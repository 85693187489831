import { gql } from "@apollo/client";

const GET_SUMMARY_PROGRESSION_USER_CONNECTED = gql`
query GetSummaryProgressionOfUserConnected {
    getSummaryProgressionOfUserConnected {
        student {
            id
            firstName
            lastName
        }
        educativeBilan {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        educativeWorkshop {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        educativeWorkshop {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        prerequisite{
            isFinished
            fileUrl
        }
    }
}
`

const GET_SUMMARY_PROGRESSION_USER = gql`
query GetSummaryProgressionOfUser ($userId: String) {
    getSummaryProgressionOfUser (userId: $userId) {
        student {
            id
            firstName
            lastName
        }
        educativeBilan {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        educativeWorkshop {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        prerequisite{
            isFinished
            fileUrl
        }
    }
}
`

const GET_SUMMARY_ALL_ACTIONS = gql`
    query GetSummaryOfAllActionsByStudent($moduleId: String) {
        getSummaryOfAllActionsByStudentQuery(moduleId: $moduleId) {
            student {
                id
                firstName
                lastName
            }
            educativeBilan {
                seenVideos
                totalVideos
                filledForm
                totalForm
                readDocuments
                totalDocuments
            }
            educativeWorkshop {
                seenVideos
                totalVideos
                filledForm
                totalForm
                readDocuments
                totalDocuments
            }
        }
    }
`

const GET_SUMMARY_PROGRESSION_BY_PROMOTIONS = gql`
query GetSummaryProgressionByPromotion($promotionId: String) {
    getSummaryOfAllActionsByStudentQuery(promotionId: $promotionId) {
        student {
            id
            firstName
            lastName
        }
        educativeBilan {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
        educativeWorkshop {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
        }
    }
}`


const GET_ALL_ACTIONS_IN_EDUCATIVE_BILAN = gql `
query getAllActionsInEducativeBilan($moduleId: String) {

    getAllActionsInEducativeBilan(moduleId: $moduleId){
        student {
            id
            firstName
            lastName
        }
        moduleSummary {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
            filledFields
            totalFields
            isFinalized
            isValidated
        }
    }
}
`


const GET_ALL_ACTIONS_IN_EDUCATIVE_WORKSHOP = gql `
query getAllActionsInEducativeWorkshop($moduleId: String) {
    getAllActionsInEducativeWorkshop(moduleId: $moduleId){
        student {
            id
            firstName
            lastName
        }
        moduleSummary {
            seenVideos
            totalVideos
            filledForm
            totalForm
            readDocuments
            totalDocuments
            filledFields
            totalFields
            isFinalized
            isValidated
        }
    }
}
`
export {GET_SUMMARY_PROGRESSION_USER_CONNECTED, GET_SUMMARY_PROGRESSION_USER, GET_SUMMARY_PROGRESSION_BY_PROMOTIONS, GET_SUMMARY_ALL_ACTIONS, GET_ALL_ACTIONS_IN_EDUCATIVE_BILAN, GET_ALL_ACTIONS_IN_EDUCATIVE_WORKSHOP };