import { Box, IconButton, Paper, Typography } from '@mui/material';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { GET_CURRENT_EDUCATIVE_BILAN, GET_CURRENT_USER_DETAILS } from 'src/graphql/queries/queries';
import { useMutation, useQuery } from '@apollo/client';
import { createRef, useContext, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';

import { ErrorText } from 'src/components/Error/ErrorText';
import { VideoContent } from '../Home/Content/Module/VideoContent';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';
import { AttachFile } from '@mui/icons-material';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { Document } from 'src/interfaces/interfaces';
import { ADD_PREREQUIS_PDF_MUTATION } from 'src/graphql/mutations/mutations';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { convertToCustomFormat, getCurrentDateTime } from 'src/utils/utils';

const defaultValue: Document = { name: '', fileContent: '' };

export const PrerequisitePanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    const [docName, setDocName] = useState<string>('');
    const [uploaderInfo, setUploaderInfo] = useState<string>('');
    const [docType, setDocType] = useState<string>('application/pdf');
    const [document, setDocument] = useState<Document>(defaultValue);
    const [addingNewDoc, setAddingNewDoc] = useState<boolean>(false);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const { loading: userLoading, error: userError, data: dataUser, refetch } = useQuery(GET_CURRENT_USER_DETAILS);
    const { loading: bilanLoadin, data: bilanData, error: bilanError, refetch: bilanRefetch } = useQuery(GET_CURRENT_EDUCATIVE_BILAN);
    
    const documentRef = createRef<HTMLInputElement>();

    const renameFile = (file: File, newName: string): File => {
        return new File([file], newName, { type: file.type });
    };

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const MAX_SIZE = 10 * 1024 * 1024;
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            const renamedFile = renameFile(file, "new-name.pdf");
            if (!['application/pdf'].includes(file.type)) {
                showErrorSnackBar('Veuillez uploader un fichier PDF');
                setDocName('');
            }else if (file.size > MAX_SIZE) {
                showErrorSnackBar('Le fichier uploadé doit faire moins de 10 Mo');
                setDocName('');
            }else {
                setDocName(file.name);
                setDocType(file.type);
                const reader = new FileReader();
                reader.onload = _handleDocReaderLoaded;
                reader.readAsBinaryString(renamedFile);
            }
        }
    };

    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docType + ';base64,' + btoa(binaryString);
        setDocument((old: Document) => ({ ...old, fileContent }));
    };

    const slugify = (text: string, separator: string = "-"): string => {
        return text
            .trim() // Remove leading/trailing whitespace
            .normalize("NFD") // Normalize accents (e.g., é -> e)
            .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
            .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove invalid characters
            .replace(/\s+/g, "_") // Replace spaces with dashes
            .replace(/-+/g, "-"); // Collapse multiple dashes
    };

    const clearInput = () => {
        setDocName('');
        setDocument(defaultValue);
    };

    const [savePdf, { loading, error, data }] = useMutation(ADD_PREREQUIS_PDF_MUTATION, {
        onCompleted: (res) => {
            showSuccessSnackBar(`Fichier transmis le ${getCurrentDateTime()}, ${res.responsePrerequisPdf.message}`);
            setAddingNewDoc(false);
            clearInput();
            bilanRefetch();
        },
        onError: () => {
            setAddingNewDoc(false);
            showErrorSnackBar('Oups, Une erreur c\'est produite');
        },
    });

    useEffect(() => {
        if(bilanData?.currentEducativeBilan?.prerequisiteFilename){
            const fileName = bilanData.currentEducativeBilan.prerequisiteFilename;
            const fileCreatedAt = convertToCustomFormat(bilanData.currentEducativeBilan.prerequisiteCreatedAt) || 'inconnu';
            setDocName(`${fileName} (uploadé le ${fileCreatedAt})`);
        }else{
            setDocName('');
        }
    },[bilanData])

    const isValid = docName && document.fileContent
    
    const getPrerequisite = () => {
        return (
            <Paper sx={styles.paperPanel} style={{width:'100%'}}>
                <Box sx={styles.rootPanel} style={{ padding: 20, backgroundColor: 'white' }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap:'30px'}}>
                        <Box sx={{padding: '0 60px'}}>
                            <ul>
                                <Typography fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold} color={baseColors.black}>
                                    Une question réflexive : et vous dans votre pratique ?
                                </Typography><br />

                                <li>
                                <Typography fontSize={13} lineHeight={3} fontFamily={baseFontFamily.OpenSansRegular} color={baseColors.black}>
                                    Décrivez une expérience vécue en stage dans laquelle vous avez été confrontés  à la prise en soin d’un patient souffrant d’une maladie chronique.
                                </Typography>
                                </li>
                                <li>
                                <Typography fontSize={13} lineHeight={3} fontFamily={baseFontFamily.OpenSansRegular} color={baseColors.black}>
                                    Racontez pourquoi vous avez choisi cette situation : problématisation et mise en difficulté.
                                </Typography>
                                </li>
                                <li>
                                <Typography fontSize={13} lineHeight={3} fontFamily={baseFontFamily.OpenSansRegular} color={baseColors.black}>
                                    De quoi auriez-vous besoin pour résoudre au moins en partie ces difficultés ?
                                </Typography>
                                </li>
                            </ul>
                        </Box>

                        <Box 
                            style={{ display: 'flex', alignItems: 'center', justifyContent:'center', cursor: 'pointer' }}
                            onClick={() => documentRef.current?.click()}
                        >
                            <IconButton>
                                <AttachFile />
                            </IconButton>
                            <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                            <CustomText fontSize={14} color={baseColors.green.primary}>
                                {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                            </CustomText>
                        </Box>
                        <Typography fontSize={13} lineHeight={3} textAlign={'center'} fontFamily={baseFontFamily.OpenSansRegular} color={baseColors.black}>
                            <i>Veuillez fournir un fichier au format PDF de moins de 10 Mo.</i>
                        </Typography>

                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
                            <CustomLoadingButton
                                disabled={!isValid}
                                handleClick={() => {
                                    if (!isValid) return;
                                    setAddingNewDoc(true);
                                    savePdf({
                                        variables: {
                                            title: `${slugify(dataUser?.currentUser?.firstName+' '+dataUser?.currentUser?.lastName+'_')}`,
                                            description: '',
                                            fileContent: document.fileContent,
                                        },
                                    });
                                }}
                                loading={addingNewDoc || userLoading}
                                width={300}
                                label={'ENREGISTRER'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Paper>
        );
    };

    return (
        <>
            {loading ? (
                <CustomCircleLoader width={60} />
            ) : error ? (
                <ErrorText>Oups, something isn't working</ErrorText>
            ) : (
                <>{getPrerequisite()}</>
            )}
        </>
    );
};
