import { Box, Paper } from '@mui/material';

import { AuthorizationCode, BilanTabMenuIconName, ModuleType } from 'src/utils/constants/constants';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { ModuleDetailContext, useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import ModuleDetailPanel from './ModuleDetailPanel';
import ModuleListPanel from '../Home/Content/Module/ModuleListPanel';
import { useEffect, useState } from 'react';
import { CurrentModuleContext } from 'src/providers/ModuleListProvider';
import { Module } from 'src/interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { ModuleNonValide } from './ModuleNonValide/ModuleNonValide';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_EDUCATIVE_BILAN } from 'src/graphql/queries/queries';
import { useSidebarContext } from 'src/providers/SidebarProvider';

export const EducativeBilanPanel = (props: any) => {
    const dispatch = useDispatch();

    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    const [selectedPanel, setSelectedPanel] = useState<string>(BilanTabMenuIconName.INFORMATION);
    const [isCreatingNewModule, setIsCreatingNewModule] = useState<boolean>(false);
    const [disableSoumettreButton, setDisableSoumettreButton] = useState<boolean>(false);
    const [isRefreshListModule, setIsRefreshListModule] = useState<boolean>(false);
    const [selectedModuleId, setSelectedModuleId] = useState<string>('');
    const [currentModule, setCurrentModule] = useState<Module>();
    const [modules, setModules] = useState<Module[]>([]);
    const [isFinalizedPrerequisite, setIsFinalizedPrerequisite] = useState(false);
    const { selectedPanel: selectedPanelGlobal, setSelectedPanel: setSelectedPanelGlobal } = useSidebarContext();

    const { loading, data, error, refetch } = useQuery(GET_CURRENT_EDUCATIVE_BILAN);

    const { codes } = useAuthorizationContext();
    const isNeedPrerequisite = codes?.includes(AuthorizationCode.FRONT_DISPLAY_PREREQUISITES_SIDEBAR_MENU);

    useEffect(()=>{
        refetch();
    },[isNeedPrerequisite]);

    useEffect(() => {
        if (data?.currentEducativeBilan?.isFinalizedPrerequisite) {
            setIsFinalizedPrerequisite(data.currentEducativeBilan?.isFinalizedPrerequisite);
        }
    }, [data, selectedPanelGlobal]);

    if(isNeedPrerequisite && !isFinalizedPrerequisite) {
        return (
            <ModuleNonValide />
        )
    }

    return (
        <Box sx={styles.rootPanel}>
            <Paper sx={styles.paperPanel}>
                <CurrentModuleContext.Provider
                    value={{
                        currentModule,
                        setCurrentModule,
                        modules,
                        setModules,
                    }}
                >
                    <ModuleDetailContext.Provider
                        value={{
                            selectedPanel,
                            setSelectedPanel,
                            isCreatingNewModule,
                            isRefreshListModule,
                            setIsRefreshListModule,
                            setIsCreatingNewModule,
                            disableSoumettreButton,
                            setDisableSoumettreButton,
                        }}
                    >
                        <ModuleListPanel
                            setIsCreatingNewModule={setIsCreatingNewModule}
                            moduleType={ModuleType.EDUCATIVE_BILAN}
                            // onSelectionChange={setSelectedModuleId}
                        />

                        <ModuleDetailPanel
                            moduleType={ModuleType.EDUCATIVE_BILAN}
                            // moduleId={selectedModuleId}
                            setSelectedModuleId={setSelectedModuleId}
                            disableSoumettreButton={disableSoumettreButton}
                            setDisableSoumettreButton={setDisableSoumettreButton}
                        />
                    </ModuleDetailContext.Provider>
                </CurrentModuleContext.Provider>
            </Paper>
        </Box>
    );
};
