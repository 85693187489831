import { DashboardPanel } from 'src/pages/Dashboard/DashboardPanel';
import { EducativeBilanPanel } from 'src/pages/EducativeBilan/EducativeBilanPanel';
import { EducativeWorkshopPanel } from 'src/pages/EducativeWorkshop/EducativeWorkshopPanel';
import { StudentProgressPanel } from 'src/pages/StudentProgress/StudentProgressPanel';
import { StudentListPanel } from 'src/pages/StudentList/StudentListPanel';
import { ToolBoxPanel } from 'src/pages/ToolBox/ToolBoxPanel';
import { MenuIconName } from 'src/utils/constants/constants';
import UserConsent from 'src/pages/UserConsent/UserConsent';
import { Paragliding } from '@mui/icons-material';
import { Setting } from 'src/pages/Setting/Setting';
import { PrerequisitePanel } from 'src/pages/Prerequisite/PrerequisitePanel';

class ContentPanelHelper {
    public displayPanel(selectedPanel: string) {
        let panel: any = null;
        switch (selectedPanel) {
            case MenuIconName.DASHBOARD:
                panel = <DashboardPanel />;
                break;
            case MenuIconName.BILAN:
                panel = <EducativeBilanPanel />;
                break;
            case MenuIconName.PREREQUISITES:
                panel = <PrerequisitePanel />;
                break;
            case MenuIconName.WORKSHOP:
                panel = <EducativeWorkshopPanel />;
                break;
            case MenuIconName.TOOL_BOX:
                panel = <ToolBoxPanel />;
                break;
            case MenuIconName.STUDENT_PROGRESS:
                panel = <StudentProgressPanel />;
                break;
            case MenuIconName.STUDENT_LIST:
                panel = <StudentListPanel />;
                break;
            case MenuIconName.USER_CONSENT:
                panel = <UserConsent />;
                break;
            case MenuIconName.SETTING:
                panel = <Setting />;
                break;
            default:
                panel = <EducativeBilanPanel />;
                break;
        }
        return panel;
    }
}

export default ContentPanelHelper;
