import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { createRef, useContext, useEffect, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { CustomInputField } from 'src/components/InputField/CustomInputField';
import { GET_ALL_USERS } from 'src/graphql/queries/queries';
import { UserAdapter } from 'src/interfaces/adapter/UserAdapter';
import { User } from 'src/interfaces/interfaces';
import { AuthContext } from 'src/providers/AuthProvider';
import { baseColors, baseFontFamily, baseScrollbar } from 'src/utils/constants/baseStyles';
import { ALLOWED_EXTENSION, Categorie } from 'src/utils/constants/constants';
import CreateUser from './CreateUser';
import UserListTab from './UserListTab';
import './UserListTab.css';
import { ACTIVATE_USER } from 'src/graphql/mutations/user.mutation';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import ActionProvider, { ActionContext } from 'src/providers/ActionProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
import { Document } from 'src/interfaces/interfaces';
import { IMPORT_USERS } from 'src/graphql/mutations/mutations';
import CustomAnomaly from 'src/components/CustomAnomaly/CustomAnomaly';
import { InputCustomField } from 'src/components/InputField/InputCustomField';
import { SearchIcon } from 'src/components/Icon/SearchIcon';
import { ReloadingContext } from 'src/providers/ReloadingProvider';

const defaultValue: Document = { name: '', fileContent: '' };
const defaulDoctype: string = 'application/pdf';

const UserSettingPanel = () => {
    const userListDefault = [
        {
            id: '-----',
            firstName: '----',
            lastName: '----',
            roles: ['STUDENT'],
            email: 'user@example.com',
            categorie: 'STUDENT',
            promotion: {
                id: '',
                name: '',
            },
        } as User,
    ];
    const [keywords, setKeywords] = useState('');
    const {
        data,
        loading,
        error,
        refetch: refetchAllUsers,
    } = useQuery(GET_ALL_USERS, {
        variables: { keywords }, // Pass the keywords variable to the query
    });

    const [userList, setUserList] = useState<User[]>(userListDefault);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [role, setRole] = useState<string>('PROFESSOR');
    const {isReload, setIsReload} = useContext(ReloadingContext);
    const { getDecodedToken } = useContext(AuthContext);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const { closeModal, setCloseModal } = useContext(ActionContext);
    const [document, setDocument] = useState<Document>(defaultValue);

    const [anomalies, setAnomalies] = useState<{ email: string; code: number }[]>([]);
    const addAnomaly = (email: string, code: number) => {
        setAnomalies((prevAnomalies) => [...prevAnomalies, { email, code }]);
    };

    const [docName, setDocName] = useState<string>('');
    const [docType, setDocType] = useState<string>(defaulDoctype);
    const documentRef = createRef<HTMLInputElement>();
    const [activateUser, datas] = useMutation(ACTIVATE_USER, {
        onCompleted: (data) => {
            setCloseModal(true);
            if (data?.activateUser?.activated) {
                showSuccessSnackBar('Utilisateur activé avec succès');
            } else {
                showSuccessSnackBar('Utilisateur désactivé avec succès');
            }
            setIsReload(true);
        },
        onError: (error) => {
            console.error(error, 'There is an error');
            showErrorSnackBar("Une erreur s'est produite lors de l'opération");
        },
    });

    const [importUser, {loading: loadingImport}] = useMutation(IMPORT_USERS, {
        onCompleted: (output) => {
            // setAnomalies([]);
            if (output?.importUsers?.status != 200) {
                showErrorSnackBar('Le fichier que vous avez importé ne correspond pas à ce qui était attendu');
            } else {
                output?.importUsers?.anomalies?.forEach((anomaly: any) => {
                    addAnomaly(anomaly.email, anomaly.code);
                });
                showSuccessSnackBar('Processus terminé');
                refetchAllUsers();
            }
        },
        onError: () => {
            showErrorSnackBar("Le fichier d'import n'est pas au bon format ou est illisible");
        },
    });

    let docsType: any;
    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            if (!['text/csv'].includes(file.type)) {
                showErrorSnackBar(
                    'documents non supporté (veuiller importe des fichier .csv)',
                );
                setDocName('');
            } else {
                setDocName(file.name);
                setDocType(file.type);
                docsType = file.type;
                const reader = new FileReader();
                reader.onload = _handleDocReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };
    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docsType + ';base64,' + btoa(binaryString);
        setDocument((old: Document) => ({ ...old, fileContent }));
        importUser({
            variables: {
                fileContent: fileContent,
            },
        });
    };

    useEffect(() => {
        if (getDecodedToken) {
            const decodedToken = getDecodedToken();
            const roles = decodedToken?.roles;
            // On retourne le role de l'utilisateur connecté
            // Le role est composé d'array contenant son role et le role par defaut(ROLE_USER)
            // ex: ["ROLE_PROFESSOR", "ROLE_USER"]
            setRole((oldValue: any) => {
                return roles.find((role: string) => role != 'USER');
            });
        }
    }, []);

    useEffect(() => {
        if (data) {
            const userList = data.listUser.map((userData: any) => {
                return UserAdapter(userData);
            });
            setUserList(userList);
        }
    }, [data]);

    useEffect(() => {
        if (isReload) {
            refetchAllUsers();
            setIsReload(false);
        }
    }, [isReload]);

    const createNewUser = () => {
        setIsOpened(true);
    };

    const handlechange = (e: string) => {
        setKeywords(e); // Update the keywords state with the entered value
        refetchAllUsers({ keywords: e });
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography
                        fontFamily={baseFontFamily.BreeSerif}
                        fontWeight={400}
                        color={baseColors.green.lightPastel}
                        fontSize={18}
                    >
                        Liste des utilisateurs
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <CustomButton
                        width="auto"
                        label="Créer un nouvel utilisateur"
                        onClick={createNewUser}
                        fontSize={14}
                        lower={true}
                    />
                    <CustomButton
                        width="auto"
                        label="Importer des utilisateurs"
                        onClick={() => documentRef.current?.click()}
                        fontSize={14}
                        lower={true}
                        loading={loadingImport}
                    />
                    <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                </Box>
            </Box>
            <Box mt={'5px'}>
                <InputCustomField
                    icon={<SearchIcon width={'15px'} height={'15px'} />}
                    label=""
                    onChange={handlechange}
                    placeholder="Rechercher un utilisateur..."
                    width={'100%'}
                />
            </Box>

            {anomalies.map((anomaly) => (
                <CustomAnomaly code={anomaly.code} email={anomaly.email} />
            ))}

            <Box>
                <UserListTab
                    loading={loading}
                    users={userList}
                    onActivate={(userData: { userId: string; activated: boolean }) => {
                        activateUser({
                            variables: {
                                userData,
                            },
                        });
                    }}
                ></UserListTab>
            </Box>

            <Spacer height={100} />
            {/* <!-- The  create user modal --> */}
            <CreateUser
                role={role}
                handleCreateNewUser={(user: User) => {
                    setUserList((oldState: User[]) => {
                        return [...oldState, user];
                    });
                }}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                setIsReload={setIsReload}
            ></CreateUser>
        </Box>
    );
};

export default UserSettingPanel;
