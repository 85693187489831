import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import {
    GET_SUMMARY_PROGRESSION_BY_PROMOTIONS,
    GET_SUMMARY_PROGRESSION_USER,
} from 'src/graphql/queries/summary.queries';
import { adaptSummaryProgression, SummaryProgression } from 'src/components/shared/SummaryProgression';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { GlobalAvancementBarChart } from 'src/components/shared/GlobalAvancementBarChart/GlobalAvancementBarChart';
import { AvancementContext } from 'src/providers/AvancementProvider';
import withFile from 'src/assets/icons/withFile.png';
import noFile from 'src/assets/icons/noFile.png';

type Props = { 
    promotionId: string, 
    needPrerequisite: boolean | null | undefined;
}

const GlobalAvancement: FC<Props> = (props) => {
    const { promotionId, needPrerequisite } = props;
    const [summaryProgression, setSummaryProgression] = useState<SummaryProgression[]>([]);
    const { reload, setReload, selectedUserId, setSelectedUserName, setSelectedUserId, setIsUserSelected, selectedUserName, isUserSelected } = useContext(AvancementContext);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [
        getProgress,
        { loading: loadingPromotion, data: dataPromotion, error: errorPromotion, refetch: refechProm },
    ] = useLazyQuery(GET_SUMMARY_PROGRESSION_BY_PROMOTIONS, {
        variables: { promotionId },
        onCompleted: (dataPromotion: any) => {
            setSelectedUserName(null);
            setIsUserSelected(false);
            setSelectedUserId(null);
            setSummaryProgression(() => {
                return dataPromotion.getSummaryOfAllActionsByStudentQuery.map(adaptSummaryProgression);
            });
        },
    });

    const {
        loading: loadingUser,
        data: dataUser,
        error: errorUser,
        refetch,
    } = useQuery(GET_SUMMARY_PROGRESSION_USER, {
        variables: { userId: selectedUserId },
        onCompleted: (dataUser: any) => {
            const summary: SummaryProgression[] = [adaptSummaryProgression(dataUser.getSummaryProgressionOfUser)];
            setSummaryProgression(summary);
        },
        skip: selectedUserId == null,
    });

    useEffect(() => {
        getProgress();
    }, []);

    useEffect(() => {
        if (reload) {
            if (!isUserSelected) {
                setLoadingData(true);
                refechProm().then(() => {
                    setLoadingData(false);
                });
                getProgress();
            } else {
                setLoadingData(true);
                refetch({ userId: selectedUserId }).then(() => {
                    setLoadingData(false);
                });
            }
        }
        setReload(false);
    }, [reload, isUserSelected]);

    if (!promotionId) {
        return <div>Aucune promotion sélectionnée</div>;
    }
    if (errorPromotion || errorUser) {
        return <div>Oupps, une erreur</div>;
    }

    const prerequisite = summaryProgression.find((item: SummaryProgression) => item?.prerequisite?.isFinished);

    const prerequisiteFile = () => {
        if(selectedUserName && needPrerequisite){
            if(prerequisite && prerequisite.prerequisite?.fileUrl){
                return (
                    <a 
                        href={prerequisite.prerequisite?.fileUrl} 
                        target='_blank' 
                        title={'Télécharger le document PDF'}
                        style={{justifyContent: "center", alignItems: "center", display: "flex", gap: "10px", textDecoration: "none"}}
                    >
                        <Typography
                                fontFamily={baseFontFamily.BreeSerif}
                                fontSize={14}
                                color={baseColors.green.cancel}
                                fontWeight={400}
                            >
                                Récit de situation clinique
                        </Typography>
                        <img src={withFile} alt="icon" width={50} height={50} />
                    </a>
                )
            }else{
                return (
                    <a 
                        href={'javascript:void(0)'} 
                        style={{cursor: "default",justifyContent: "center", alignItems: "center", display: "flex",gap: "10px",textDecoration: "none"}} 
                        title={`Pas de document disponible pour ${selectedUserName}`}>
                        <Typography
                                fontFamily={baseFontFamily.BreeSerif}
                                fontSize={14}
                                color={baseColors.green.cancel}
                                fontWeight={400}
                            >
                                Récit de situation clinique
                        </Typography>
                        <img src={noFile} alt="icon" width={50} height={50} />
                    </a>
                )
            }
        }
    }

    return (
        <>
            {loadingPromotion || loadingUser || loadingData ? (
                <Box display='flex' justifyContent='center' alignItems='center' mt='25%'>
                    <CustomCircleLoader width={60} />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '75vh',
                            padding: '25px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center'}}>
                            <Typography
                                fontFamily={baseFontFamily.BreeSerif}
                                fontSize={24}
                                color={baseColors.green.cancel}
                                fontWeight={400}
                            >
                                {selectedUserName ? 'Avancement de ' + selectedUserName : 'Avancement global de la promotion'}
                            </Typography>
                            { prerequisiteFile() }
                        </Box>
                        <GlobalAvancementBarChart data={summaryProgression} />
                    </Box>
                </>
            )}
        </>
    );
};
export { GlobalAvancement };
