import { Modal, Box, TextField, TextareaAutosize, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors } from 'src/utils/constants/baseStyles';
import { CustomButton } from 'src/components/Button/CustomButton';
import { DELETE_VIDEO } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrentModule, setCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
import { TYPE_VIDEO_ANNOTEE, TYPE_VIDEO_EXPLICATIVE, Video } from 'src/interfaces/interfaces';
import { updateModule } from 'src/redux/features/listModules/listModulesSlice';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '10vh',
    minHeight: 200,
    borderRadius: 2,
    bgcolor: 'background.paper',
    p: 4,
};

export const DeleteVideo = (props: any) => {
    const dispatch = useDispatch();
    const currentModule = useSelector(selectCurrentModule);
    const handleClose = () => props.setOpenModal(false);
    const moduleId = props.moduleId;
    const category = props.category;
    const [deleteVideo, { data, loading, error }] = useMutation(DELETE_VIDEO, {
        onCompleted: (data) => {
            const updatedModule = { ...currentModule };
            if (category === TYPE_VIDEO_EXPLICATIVE) updatedModule.video = null;
            if (category === TYPE_VIDEO_ANNOTEE) updatedModule.videoAnnote = null;
            dispatch(updateModule({ moduleId: updatedModule.id, newValue: updatedModule }));
            // dispatch(setCurrentModule(updatedModule));
            handleClose();
            // window.location.reload();
        },
        onError: (error) => {
            alert('delete video error');
        },
    });
    const handleDelete = () => {
        deleteVideo({ variables: { category, moduleId } });
    };

    return (
        <CustomConfirm
            modalVisible={props.open}
            updateModalVisible={handleClose}
            yesLabel={'Oui'}
            noLabel={'Non'}
            message={'Voulez-vous vraiment supprimer cette vidéo?'}
            disableBackdropClick={false}
            callBackconfirm={handleDelete}
        ></CustomConfirm>
    );
};
